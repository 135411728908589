import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import Table from './Table';
import Filters, { FiltersProps } from './Filters';
import BackLink from '../../components/BackLink';
import Tooltip from '../../components/Tooltip/index';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { IndicationDTO } from '../../dtos/IndicationDTO';
import { ListDTO } from '../../dtos/ResultsDTO';
import api from '../../services/api';
import { getIsoDate } from '../../utils/format';

interface FetchDTO {
  prevData: IndicationDTO[];
  limit: number;
  page: number;
}

interface StateProps {
  data: IndicationDTO[];
  page: number;
  perPage: string;
  total: number;
}

const initialState: StateProps = {
  data: [],
  page: 0,
  perPage: '10',
  total: 0,
};

const defaultFilters: FiltersProps = {
  startDate: null,
  endDate: null,
  status: '',
  name: null,
};

const Indications: React.FC = () => {
  const [state, setState] = useState<StateProps>(initialState);
  const [filters, setFilters] = useState(defaultFilters);
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const classes = useStyles();

  const fetchData = useCallback(
    async (data: FetchDTO) => {
      const params = { page: data.page, limit: data.limit };

      if (filters.status) Object.assign(params, { status: filters.status });
      if (filters.name) Object.assign(params, { name: filters.name });
      if (filters.startDate)
        Object.assign(params, { startDate: getIsoDate(filters.startDate) });
      if (filters.endDate)
        Object.assign(params, { endDate: getIsoDate(filters.endDate) });

      startLoading();
      api
        .get<ListDTO<IndicationDTO>>('/api/indication', { params })
        .then((result) => {
          setState({
            data: [...data.prevData, ...result.data.data],
            page: result.data.current_page,
            perPage: result.data.per_page,
            total: result.data.total,
          });
        })
        .catch((error) => {
          showApiError(error, 'Erro obtendo a lista de indicações');
        })
        .finally(() => endLoading());
    },
    [
      endLoading,
      filters.endDate,
      filters.name,
      filters.startDate,
      filters.status,
      showApiError,
      startLoading,
    ]
  );

  useEffect(() => {
    fetchData({ page: 1, limit: 10, prevData: [] });
  }, [fetchData]);

  const handleFilter = useCallback(
    (definedFilters: FiltersProps) => {
      setFilters(definedFilters);
      fetchData({
        page: 1,
        limit: 10,
        prevData: [],
      });
    },
    [fetchData]
  );

  return (
    <>
      <div className={classes.backBtn}>
        <BackLink floating={false} color="dark" />
      </div>
      <div className={classes.container}>
        <div className={classes.title}>
          <div>
            <Typography className="title-text">Indicações</Typography>
            <Tooltip>
              <p>
                <strong>Aqui</strong> você confere <strong>todas</strong> as
                suas <strong>indicações realizadas</strong>.
              </p>
              <p>
                Utilize o <strong>filtro</strong> para ver indicações em{' '}
                <strong>determinada data</strong>, por <strong>status</strong>{' '}
                ou procure por uma <strong>Razão Social</strong> específica.
              </p>
              <p>
                <strong>Ao lado</strong> de <strong>cada indicação</strong>{' '}
                (ícone do olho) você poderá{' '}
                <strong>clicar para visualizar detalhes</strong>.
              </p>
            </Tooltip>
          </div>
          <Filters filters={filters} onFilter={handleFilter} />
        </div>
        <Table
          data={state.data}
          total={state.total}
          next={() =>
            fetchData({
              page: state.page + 1,
              limit: parseInt(state.perPage, 10),
              prevData: state.data,
            })
          }
        />
      </div>
    </>
  );
};

export default Indications;
