import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import SocialButton from './SocialButton';
import GmailLogo from '../../assets/gmail.png';
import FacebookLogo from '../../assets/facebook.png';
import { SocialSignInData, useAuth } from '../../context/auth';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';

interface SuccessProps {
  profile: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    profilePicURL: string;
    gender?: string;
  };
  provider: 'google' | 'facebook';
  token: {
    accessToken: string;
    expiresAt: string;
    expiresIn?: string;
    firstIssued_at?: string;
  };
}

const SocialLogin: React.FC = () => {
  const { socialSignIn } = useAuth();
  const history = useHistory();
  const { showApiError } = useErrorHandle();
  const { search } = useLocation();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const classes = useStyles();

  const trk = new URLSearchParams(search).get('trk');

  const handleSuccess = useCallback(
    async (values: SuccessProps) => {
      startLoading();
      try {
        const data: SocialSignInData = {
          grant_type: 'social',
          client_id: '00000',
          client_secret: '00000',
          provider: values.provider,
          access_token: values.token.accessToken,
        };
        if (trk) Object.assign(data, { trk });

        await socialSignIn(data);

        history.push('/');
      } catch (error) {
        showApiError(error, 'Erro efetuando o login social. Tente novamente');
        endLoading();
      }
    },
    [startLoading, trk, socialSignIn, history, showApiError, endLoading]
  );

  const handleFailure = useCallback(
    (values: unknown) => {
      console.error(values);
      addToast({
        message: 'Erro efetuando o login social. Tente novamente',
        type: 'error',
      });
    },
    [addToast]
  );

  return (
    <>
      <div className={classes.socialLoginSep}>
        <div className="line" />
        <Typography component="span" className="title">
          Entrar com
        </Typography>
        <div className="line" />
      </div>
      <div className={classes.socialLoginContainer}>
        <Card className={classes.socialLoginItem}>
          <SocialButton
            provider="google"
            appId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            onLoginSuccess={handleSuccess}
            onLoginFailure={handleFailure}
          >
            <img src={GmailLogo} alt="Gmail" />
          </SocialButton>
        </Card>
        <Typography component="span" className="sl-text">
          ou
        </Typography>
        <Card className={classes.socialLoginItem}>
          <SocialButton
            provider="facebook"
            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID || ''}
            onLoginSuccess={handleSuccess}
            onLoginFailure={handleFailure}
          >
            <img src={FacebookLogo} alt="Facebook" />
          </SocialButton>
        </Card>
      </div>
    </>
  );
};

export default SocialLogin;
