import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface WithdrawStatusProps {
  title: string;
  status: number;
}

interface StatusElementProps {
  id: number;
  color?: string;
  title: string;
}

const indicatorsDef: StatusElementProps[] = [
  { id: 0, title: 'Solicitado', color: '#FF274F' },
  { id: 1, title: 'Solicitado', color: '#FF960C' },
  { id: 2, title: 'Agendado', color: '#024DD6' },
  { id: 3, title: 'Pago', color: '#13B56F' },
];

const StatusElement: React.FC<{ status: number }> = ({ status }) => {
  const classes = useStyles();
  const element = indicatorsDef.filter((item) => item.id === status)[0];

  return (
    <div
      className={classes.circle}
      style={{ background: element.color }}
      title={element.title}
    >
      {' '}
    </div>
  );
};

const WithdrawStatus: React.FC<WithdrawStatusProps> = ({ title, status }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.indicatorsContainer}>
        {status === 0 ? (
          <>
            <StatusElement status={1} />
            <StatusElement status={0} />
          </>
        ) : (
          <>
            {Array.from(Array(status).keys()).map((item) => (
              <StatusElement key={item + 1} status={item + 1} />
            ))}
          </>
        )}
      </span>
      <Typography component="div" className={classes.statusTitle}>
        {title}
      </Typography>
    </div>
  );
};

export default WithdrawStatus;
