import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: '25px 30px',
    width: '95%',
    maxWidth: '370px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '37px',
  },
  text: {
    fontSize: '13px',
    padding: '0 10px',
    textAlign: 'center',
    marginBottom: '37px',
  },
  field: {
    marginBottom: 62,

    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
    },
  },
  button: {
    padding: 20,
    fontWeight: 700,
    textTransform: 'unset',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  footerContainer: {
    width: '95%',
    maxWidth: '370px',
    textAlign: 'center',
  },
  footerText: {
    color: '#ACACAC',
    fontSize: '12px',
    marginBottom: 6,
    padding: '0 3px',
  },
  footerCopyright: {
    color: '#ACACAC',
    fontSize: '12px',
    marginBottom: 16,
  },
}));
