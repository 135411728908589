import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import Image from '../../../assets/tutorial/imagem_tutorial_02.png';

const Page2: React.FC<{ width: number }> = ({ width }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ minWidth: `${width}px` }}>
      <div className={classes.imageContainer}>
        <img src={Image} alt="Imagem do tutorial" className={classes.image} />
      </div>
      <div className={classes.titleContainer}>
        <Typography component="h1" className={classes.title}>
          MEUS GANHOS
        </Typography>
        <div className={classes.division} />
      </div>
      <Typography className={classes.text}>
        No menu “Ganhos” você visualiza o valor total do mês vigente. Acompanhe
        a situação dos seus valores e também a movimentação do seu histórico de
        saques.
      </Typography>
    </div>
  );
};

export default Page2;
