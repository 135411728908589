import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import Name from './Name';
import Site from './Site';
import Cnpj from './Cnpj';
import ContactName from './ContactName';
import ContactEmail from './ContactEmail';
import ContactPhone from './ContactPhone';
import Observation from './Observation';
import Confirm from './Confirm';
import { FormStep, FormStepper } from '../../components/FormSteps/index';
import {
  checkCnpjAvailability,
  cnpjValidator,
  emailValidator,
  phoneValidator,
  urlValidator,
} from '../../utils/validators';
import api from '../../services/api';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import { NewIndicationFormData } from './props';
import { cleanNumericString } from '../../utils/format';
import CloseBtn from '../../components/CloseBtn';

const initialValues: NewIndicationFormData = {
  name: '',
  site: '',
  document: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  observation: '',
  consent: false,
};

const Indicate: React.FC = () => {
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async ({
      name,
      site,
      document,
      contactName,
      contactEmail,
      contactPhone,
      observation,
      consent,
    }: FormikValues) => {
      const data = {
        name,
        site,
        document: cleanNumericString(document),
        consent,
        contact: {
          name: contactName,
          email: contactEmail,
          phone: cleanNumericString(contactPhone),
          observation,
        },
      };

      startLoading();
      api
        .post('/api/indication-v3', data)
        .then(() => {
          addToast({
            message: 'Cadastro completado com sucesso',
            type: 'success',
          });
          history.push('/indicacao-concluida');
        })
        .catch((error) => {
          showApiError(error, 'Erro salvando a indicação');
        })
        .finally(() => endLoading());
    },
    [addToast, endLoading, history, showApiError, startLoading]
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <CloseBtn destination="/" />
        <FormStepper
          initialValues={initialValues}
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
        >
          <FormStep
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Nome da empresa é obrigatório'),
            })}
          >
            <Name />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape(
              {
                site: Yup.string()
                  .nullable()
                  .notRequired()
                  .when('site', {
                    is: (value: string) => value?.length,
                    then: (schema) =>
                      schema.test('url', 'URL inválida', urlValidator),
                  }),
              },
              [['site', 'site']]
            )}
          >
            <Site />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              document: Yup.string()
                .required('CNPJ da empresa é obrigatório')
                .test('cnpj', 'CNPJ inválido', cnpjValidator)
                .test(
                  'UnusedCNPJ',
                  'Este documento já foi indicado por você',
                  checkCnpjAvailability
                ),
            })}
          >
            <Cnpj />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              contactName: Yup.string().required(
                'Nome do contato é obrigatório'
              ),
            })}
          >
            <ContactName />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              contactEmail: Yup.string()
                // .required('E-mail do contato é obrigatório')
                .test('Email', 'E-mail inválido', (value) =>
                  emailValidator(value)
                ),
            })}
          >
            <ContactEmail />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              contactPhone: Yup.string()
                .required('Telefone do contato é obrigatório')
                .test('Phone', 'Telefone inválido', (value) =>
                  phoneValidator(value)
                ),
            })}
          >
            <ContactPhone />
          </FormStep>
          <FormStep>
            <Observation />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              consent: Yup.boolean().oneOf(
                [true],
                'Precisa dar seu consentimento'
              ),
            })}
          >
            <Confirm />
          </FormStep>
        </FormStepper>
      </div>
    </div>
  );
};

export default Indicate;
