import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

import { useStyles } from './styles';

interface CloseBtnProps {
  destination?: string;
}

const CloseBtn: React.FC<CloseBtnProps> = ({ destination }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleBtnClick = useCallback(() => {
    if (destination) {
      history.push(destination);
    } else {
      history.goBack();
    }
  }, [destination, history]);

  return (
    <Box className={classes.root} onClick={handleBtnClick}>
      <Typography component="span">fechar</Typography>
      <FaTimes />
    </Box>
  );
};

export default CloseBtn;
