import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import { getCurrencyBr } from '../../utils/format';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.background.paper,
    padding: '24px 36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '30px 0',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      margin: 0,
    },

    '&::before': {
      content: '"$"',
      position: 'absolute',
      left: '-15px',
      color: 'rgba(255, 255, 255, 0.12)',
      fontSize: '150px',
      fontWeight: 900,
      [theme.breakpoints.up('lg')]: {
        fontSize: '220px',
        left: 'unset',
        right: -10,
      },
    },
  },
  text: {
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  amount: {
    fontSize: '24px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  button: {
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '10px 20px',
  },
}));

interface EarningsProps {
  amount: number;
}

const Earnings: React.FC<EarningsProps> = ({ amount }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div>
        <Typography component="div" className={classes.text}>
          A receber
        </Typography>
        <Typography component="div" className={classes.amount}>
          {getCurrencyBr(amount)}
        </Typography>
      </div>
      <Button
        type="button"
        variant="contained"
        className={classes.button}
        onClick={() => history.push('/ganhos')}
      >
        Ganhos
      </Button>
    </Card>
  );
};

export default Earnings;
