import api from '../services/api';

export const cpfValidator = (value?: string): boolean => {
  if (!value) return false;

  const cpf = value.replace(/\D/g, '');

  if (cpf.length !== 11 || !Array.from(cpf).filter((e) => e !== cpf[0]).length)
    return false;

  let same = true;

  for (let i = 0; i < 10; i += 1) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      same = false;
      break;
    }
  }

  if (same) return false;

  const checkResult = (sum: number, digit: string): boolean => {
    let rest = (sum * 10) % 11;
    if (rest > 9) rest = 0;
    if (rest !== parseInt(digit, 10)) return false;
    return true;
  };

  let sum = 0;
  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  if (!checkResult(sum, cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  if (!checkResult(sum, cpf.substring(10, 11))) return false;

  return true;
};

export const checkCpfAvailability = (value?: string): Promise<boolean> => {
  return new Promise((resolve, _reject) => {
    const cpf = value ? value.replace(/\D/g, '') : null;
    if (!cpf || cpf.length !== 11 || !cpfValidator(cpf)) {
      resolve(false);
      return;
    }
    api
      .get<{ message: string }>(`/api/public/document-verification/${cpf}`)
      .then(() => resolve(true))
      .catch((error) => {
        console.error(error);
        resolve(false);
      });
  });
};

export const cnpjValidator = (value?: string): boolean => {
  if (!value) return false;

  const cnpj = value.replace(/\D/g, '');

  if (cnpj.length !== 14) return false;

  let same = true;

  for (let i = 0; i < 13; i += 1) {
    if (cnpj.charAt(i) !== cnpj.charAt(i + 1)) {
      same = false;
      break;
    }
  }

  if (same) return false;

  const checkResult = (sum: number, digit: string): boolean => {
    const rest = sum % 11;
    const result = rest < 2 ? 0 : 11 - rest;
    if (result !== parseInt(digit, 10)) return false;
    return true;
  };

  let numbers = cnpj.substring(0, 12);
  const digits = cnpj.substring(12);
  let position = 5;
  let sum = 0;

  for (let i = 12; i > 0; i -= 1) {
    sum += parseInt(numbers.charAt(12 - i), 10) * position;
    position -= 1;
    if (position < 2) position = 9;
  }

  if (!checkResult(sum, digits[0])) return false;

  numbers = cnpj.substring(0, 13);
  position = 6;
  sum = 0;

  for (let i = 13; i > 0; i -= 1) {
    sum += parseInt(numbers.charAt(13 - i), 10) * position;
    position -= 1;
    if (position < 2) position = 9;
  }

  if (!checkResult(sum, digits[1])) return false;

  return true;
};

export const checkCnpjAvailability = (value?: string): Promise<boolean> => {
  return new Promise((resolve, _reject) => {
    const cnpj = value ? value.replace(/\D/g, '') : null;
    if (!cnpj || cnpj.length !== 14 || !cnpjValidator(cnpj)) {
      resolve(false);
      return;
    }
    api
      .post<{ message: string }>('/api/check-cnpj', { cnpj })
      .then(() => resolve(true))
      .catch((error) => {
        console.error(error);
        resolve(false);
      });
  });
};

export const emailValidator = (email?: string): boolean => {
  if (!email) return true;

  const [localPart, domainPart, ...rest] = email.split('@');
  if (!domainPart || rest.length > 0) return false;

  const localRE = /^[a-z0-9._+-=/?^`{|}~!#$%&*']+$/i;
  const domainRE = /^[a-z0-9-.]+$/i;
  const forbiddenREs = [/^\./, /\.$/, /\.{2}/];
  let valid = true;

  if (!localRE.test(localPart) || !domainRE.test(domainPart)) valid = false;

  forbiddenREs.forEach((re) => {
    if (re.test(localPart) || re.test(domainPart)) valid = false;
  });

  return valid;
};

export const urlValidator = (url?: string | null): boolean => {
  if (!url) return false;

  const re =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

  return re.test(url);
};

export const phoneValidator = (phone?: string): boolean => {
  if (!phone) return false;

  const value = phone.replace(/\D/g, '');

  return value.length === 10 || value.length === 11;
};
