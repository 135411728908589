import React from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from '../../context/auth';
import { fullName } from '../../utils/format';

interface AvatarProps extends MuiAvatarProps {
  size: number;
  border?: number | boolean;
  borderColor?: string;
}

const Avatar: React.FC<AvatarProps> = ({ size, border, borderColor }) => {
  const { user } = useAuth();

  const classes = makeStyles((theme) => ({
    root: {
      background: theme.palette.primary.main,
      width: size,
      height: size,
      fontSize: Math.round(size / 3),
      border: border
        ? `${typeof border === 'number' ? border : 2}px solid ${
            borderColor || 'transparent'
          }`
        : 0,
    },
  }))();

  return (
    <MuiAvatar
      alt={fullName(user.firstname, user.lastname)}
      src={user.image && user.image_url ? user.image_url : undefined}
      className={classes.root}
      imgProps={{ title: fullName(user.firstname, user.lastname) }}
    >
      {[user.firstname, user.lastname]
        .map((item) => {
          return item.substr(0, 1);
        })
        .join('')
        .toLocaleUpperCase()}
    </MuiAvatar>
  );
};

export default Avatar;
