import React from 'react';
import { Typography } from '@material-ui/core';
import { Field } from 'formik';
import { TextFieldProps } from 'formik-material-ui';

import MaskedField from '../../components/MaskedField';
import { PhoneInput } from '../../components/MaskedInput';

const Phone: React.FC = () => {
  return (
    <>
      <Typography className="title">Qual seu número de telefone?</Typography>
      <div className="input">
        <Typography className="label">Insira seu telefone com DDD.</Typography>
        <Field
          name="phone"
          component={(props: TextFieldProps) => (
            <MaskedField component={PhoneInput} {...props} />
          )}
          fullWidth
          autoFocus
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default Phone;
