import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import Image from '../../../assets/tutorial/imagem_tutorial_04.png';

const Page4: React.FC<{ width: number }> = ({ width }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ minWidth: `${width}px` }}>
      <div className={classes.imageContainer}>
        <img src={Image} alt="Imagem do tutorial" className={classes.image} />
      </div>
      <div className={classes.titleContainer}>
        <Typography component="h1" className={classes.title}>
          LINK EXCLUSIVO
        </Typography>
        <div className={classes.division} />
      </div>
      <Typography className={classes.text}>
        Na parte inferior da página inicial, você encontra o seu link exclusivo
        para compartilhamento. Convide seus contatos para participarem do
        programa de indicação e ganhe também com isso.
      </Typography>
    </div>
  );
};

export default Page4;
