import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  backBtn: {
    width: 389,
    maxWidth: '95%',
    textAlign: 'left',
    margin: 30,
  },
  container: {
    background: theme.palette.background.paper,
    width: 389,
    maxWidth: '95%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: 'unset',
      maxWidth: 1000,
      margin: '0 auto',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    fontSize: '15px',
    fontWeight: 600,

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .title-text': {
        fontWeight: 600,
      },
    },

    '& .MuiButton-root': {
      color: theme.palette.primary.main,
      padding: 0,
    },
  },
  indicationsTable: {
    marginBottom: 0,

    '& .MuiTableHead-root': {
      background: theme.palette.primary.main,

      '& .MuiTableCell-head': {
        color: theme.palette.background.paper,
        fontSize: '13px',
        fontWeight: 700,
      },
    },

    '& .MuiTableCell-root': {
      fontWeight: 700,
    },

    '& .circle-card': {
      borderRadius: '50%',
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& .circle-button': {
        color: theme.palette.primary.main,
        padding: 0,
      },
    },
  },
  filtersContainer: {
    width: '100%',
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: 385,
    },
  },
  filtersCloseBtn: {
    float: 'right',
    margin: 10,
    color: '#000',
  },
  filtersTitle: {
    fontSize: '13px',
    fontWeight: 600,
    margin: '40px 0 10px',
  },
  dateContainer: {
    display: 'flex',
  },
  filtersField: {},
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  filtersBtn: {
    margin: 80,
  },
}));
