import React from 'react';

import { useStyles } from './styles';
import { StatusTransactionsDTO } from '../../dtos/IndicationDTO';

interface IndicationStatusProps {
  transactions: StatusTransactionsDTO[];
}

interface StatusElementProps {
  color?: string;
  title: string;
}

const sortTransactions = (
  transactions: StatusTransactionsDTO[]
): StatusTransactionsDTO[] => {
  return transactions.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateA > dateB ? 1 : -1;
  });
};

const getStatusName = (transactions: StatusTransactionsDTO[]): string => {
  if (transactions.length > 0) {
    const active = transactions.slice(-1);
    return active[0].indication_status.name_app;
  }
  return 'Aguardando';
};

const StatusElement: React.FC<StatusElementProps> = ({ color, title }) => (
  <div
    className="circle"
    style={color ? { background: `#${color}` } : undefined}
    title={title}
  >
    {' '}
  </div>
);

const IndicationStatus: React.FC<IndicationStatusProps> = ({
  transactions,
}) => {
  const classes = useStyles();
  const sortedTransactions = sortTransactions(transactions);

  return (
    <div className={classes.root}>
      <div className="status-container">
        {sortedTransactions.map((item) => (
          <StatusElement
            key={item.id}
            color={item.indication_status.color}
            title={item.indication_status.name_app}
          />
        ))}
      </div>
      {getStatusName(sortedTransactions)}
    </div>
  );
};

export default IndicationStatus;
