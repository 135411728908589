import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

const Privacy: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Declaração de privacidade
      </Typography>
      <Typography>Conteúdo pendente</Typography>
    </div>
  );
};

export default Privacy;
