import React, { useCallback, useState } from 'react';
import { Card, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { FaPlus, FaTimes } from 'react-icons/fa';

import { useStyles } from './styles';
import ContactForm, { ValuesProps } from './ContactForm';

interface ContactAddProps {
  indicationId: number;
  onUpdate: () => void;
}

const ContactAdd: React.FC<ContactAddProps> = ({ onUpdate, indicationId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const initialValues: ValuesProps = {
    name: '',
    email: '',
    phone: '',
    occupation: null,
    contact_date: null,
    shift_to_contact: null,
    observation: null,
  };

  return (
    <Card className={classes.circleCard} variant="elevation">
      <IconButton className={classes.circleButton} onClick={handleOpen}>
        <FaPlus />
      </IconButton>
      <Dialog open={isOpen}>
        <div>
          <IconButton onClick={handleClose} className={classes.closeBtn}>
            <FaTimes />
          </IconButton>
        </div>
        <DialogContent>
          <ContactForm
            indicationId={indicationId}
            initialValues={initialValues}
            onUpdate={onUpdate}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default ContactAdd;
