import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { useStyles } from './styles';
import WithdrawDetails from './WithdrawDetails';
import WithdrawStatus from '../../components/WithdrawStatus';
import { getDateBr } from '../../utils/format';
import { WithdrawPaymentDTO } from '../../dtos/WithdrawDTO';

interface TableComponentProps {
  data: WithdrawPaymentDTO[];
  total: number;
  loading: boolean;
  onPageChange(): void;
}

const TableComponent: React.FC<TableComponentProps> = ({
  data,
  total,
  loading,
  onPageChange,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Typography component="div" className={classes.title}>
        Histórico de Saques
      </Typography>
      {Array.isArray(data) && data.length > 0 ? (
        <InfiniteScroll
          dataLength={data?.length || 0}
          next={onPageChange}
          hasMore={data.length < total}
          loader={<Typography>Carregando...</Typography>}
        >
          <TableContainer>
            {loading && data.length === 0 ? (
              <CircularProgress size={50} />
            ) : (
              <Table className={classes.indicationsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Solicitado</TableCell>
                    <TableCell align="center">Previsão de pagamento</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Valor</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5}>Sem resultados</TableCell>
                    </TableRow>
                  ) : (
                    data.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell align="center">
                          {getDateBr(item.created_at)}
                        </TableCell>
                        <TableCell align="center">
                          {getDateBr(item.payment_date)}
                        </TableCell>
                        <TableCell align="center">
                          <WithdrawStatus
                            status={item.status}
                            title={item.status_name}
                          />
                        </TableCell>
                        <TableCell align="center">R$ {item.total}</TableCell>
                        <TableCell align="center">
                          <WithdrawDetails withdraw={item} />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </InfiniteScroll>
      ) : null}
    </Paper>
  );
};

export default TableComponent;
