export const palette = {
  primary: {
    main: '#0054F0',
  },
  secondary: {
    main: '#FF274F',
  },
  error: {
    main: '#FF5171',
  },
  warning: {
    main: '#FFD500',
  },
  info: {
    main: '#FF960C',
  },
  success: {
    main: '#13B56F',
  },
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
