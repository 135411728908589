import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiInputAdornment-root': {
      cursor: 'pointer',
      color: '#CDD4D9',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 389,
    maxWidth: '95%',
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
    minHeight: '100vh',
    padding: 30,
    position: 'relative',

    '& form': {
      width: '100%',
    },

    '& .title': {
      color: theme.palette.background.paper,
      fontSize: '24px',
      marginBottom: 10,
    },

    '& .text': {
      color: theme.palette.background.paper,
      fontSize: '16px',

      '& .error': {
        color: theme.palette.error.main,
      },
    },

    '& .input': {
      color: theme.palette.background.paper,

      '& ~ .input': {
        marginTop: 50,
      },

      '& .label': {
        color: '#85B0FF',
        fontSize: '16px',
        marginBottom: 8,
      },

      '& .MuiInputBase-root': {
        color: theme.palette.background.paper,
      },

      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottomColor: theme.palette.background.paper,
      },

      '& .MuiInputBase-input': {
        paddingLeft: 4,
      },

      '& .MuiInputBase-input::placeholder': {
        color: '#347BFF',
        opacity: 1,
        fontSize: '18px',
      },
    },
  },
}));
