import React from 'react';
import {
  Box,
  Card,
  Grid,
  useMediaQuery,
  Typography,
  Theme,
} from '@material-ui/core';

import { useStyles } from './styles';
import { getCurrencyBr } from '../../utils/format';
import { ReactComponent as TotalPaid } from '../../assets/total_paid.svg';
import { ReactComponent as TotalWaiting } from '../../assets/total_waiting.svg';

interface SummaryProps {
  totalPaid?: number | null;
  totalWaiting?: number | null;
}

const Summary: React.FC<SummaryProps> = ({ totalPaid, totalWaiting }) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Grid
        container
        spacing={matches ? 2 : 0}
        className={classes.summaryContainer}
      >
        <Grid item xs={12} lg={4} xl={12}>
          <Typography className="summary-title">
            Veja o que você já faturou com indikeganhe
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} xl={12}>
          <Box className="summary-item">
            <TotalPaid />
            <div>
              <Typography className="summary-type">Total Retirado</Typography>
              <Typography className="summary-amount">
                {getCurrencyBr(totalPaid || 0)}
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} xl={12}>
          <Box className="summary-item">
            <TotalWaiting />
            <div>
              <Typography className="summary-type">
                Aguardando pagamento
              </Typography>
              <Typography className="summary-amount">
                {getCurrencyBr(totalWaiting || 0)}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Summary;
