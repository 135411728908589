import React from 'react';
import { Typography } from '@material-ui/core';
import { Field } from 'formik';
import { TextFieldProps } from 'formik-material-ui';

import MaskedField from '../../components/MaskedField';
import { CnpjInput } from '../../components/MaskedInput';

const Cnpj: React.FC = () => {
  return (
    <>
      <Typography className="title">Qual o cnpj da empresa?</Typography>
      <div className="input">
        <Typography className="label">Ex.: 11.222.333/0001-00</Typography>
        <Field
          name="document"
          component={(props: TextFieldProps) => (
            <MaskedField component={CnpjInput} autoFocus {...props} />
          )}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default Cnpj;
