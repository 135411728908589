import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 30,
    width: 389,
    maxWidth: '95%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 1500,
    },
  },
  indicationsTableContainer: {
    marginBottom: 30,

    '& .title': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 20px',
      fontSize: '15px',

      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .title-text': {
          fontWeight: 600,
        },
      },

      '& .MuiButton-root': {
        color: theme.palette.primary.main,
        padding: 0,
      },
    },
    '& .empty-msg': {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 500,
      marginBottom: 30,
      '& a': {
        color: theme.palette.primary.main,
        fontWeight: 700,
        textDecoration: 'none',
      },
    },
  },
  indicationsTable: {
    marginBottom: 0,

    '& .MuiTableHead-root': {
      background: theme.palette.primary.main,

      '& .MuiTableCell-head': {
        color: theme.palette.background.paper,
        fontSize: '13px',
        fontWeight: 700,
      },
    },

    '& .MuiTableCell-root': {
      fontWeight: 700,
    },

    '& .circle-card': {
      borderRadius: '50%',
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& .circle-button': {
        color: theme.palette.primary.main,
        padding: 0,
      },
    },
  },
}));
