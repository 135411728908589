import React from 'react';
import { Typography } from '@material-ui/core';
import { Field } from 'formik';
import { TextFieldProps } from 'formik-material-ui';

import MaskedField from '../../components/MaskedField';
import { PhoneInput } from '../../components/MaskedInput';

const ContactPhone: React.FC = () => {
  return (
    <>
      <Typography className="title">Qual o telefone?</Typography>
      <div className="input">
        <Typography className="label">Insira o número com DDD.</Typography>
        <Field
          name="contactPhone"
          component={(props: TextFieldProps) => (
            <MaskedField component={PhoneInput} autoFocus {...props} />
          )}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default ContactPhone;
