/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Card, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataset } from 'chart.js';
import { FaRegCalendarCheck } from 'react-icons/fa';

import { useStyles } from './styles';
import Tooltip from '../../components/Tooltip/index';
import { useToast } from '../../context/toast';
import { getDateBr, getChartDateLabel } from '../../utils/format';
import { IndicationFullDTO, PaymentDTO } from '../../dtos/IndicationDTO';

interface ChartProps {
  indication: IndicationFullDTO;
  paymentData: PaymentDTO[] | null;
}

interface CustomChartDataset extends ChartDataset<'bar'> {
  backgroundColor: string[];
  borderColor: string[];
  labels: string[];
}

interface LegendProps {
  id: number;
  amount: number;
  title: string;
  color: string;
}

interface PaymentState {
  id: number;
  color: string;
  title: string;
}

const paymentStates: PaymentState[] = [
  { id: 0, color: '#FF274F', title: 'Cancelado' },
  { id: 1, color: '#0054F0', title: 'Acordo' },
];

const processData = (
  data: PaymentDTO[]
): { data: ChartData<'bar'>; legends: LegendProps[] } => {
  const labels: string[] = [];
  const legends: LegendProps[] = [];
  const dataset: CustomChartDataset = {
    data: [],
    backgroundColor: [],
    borderColor: [],
    labels: [],
  };

  if (data.length > 0) {
    data.forEach((item, idx) => {
      const payment = item.status === 0 ? paymentStates[0] : paymentStates[1];
      dataset.data.push(parseFloat(item.value));
      dataset.backgroundColor.push(payment.color);
      dataset.borderColor.push(payment.color);
      dataset.labels.push(`PARCELA ${idx + 1}`);
      labels.push(getChartDateLabel(item.date.payment_liberate_date));
      let existingLegend = false;
      legends.forEach((legend, legendIdx) => {
        if (legend.id === payment.id) {
          existingLegend = true;
          legends[legendIdx].amount += parseFloat(item.value);
        }
      });
      if (!existingLegend) {
        legends.push({
          id: payment.id,
          amount: parseFloat(item.value),
          title: payment.title,
          color: payment.color,
        });
      }
    });
  }

  return {
    data: { datasets: [dataset], labels },
    legends,
  };
};

const ChartComponent: React.FC<ChartProps> = ({ indication, paymentData }) => {
  const [data, setData] = useState<ChartData<'bar'> | null>(null);
  const [legends, setLegends] = useState<LegendProps[] | null>(null);
  const { addToast } = useToast();
  const classes = useStyles();

  useEffect(() => {
    if (paymentData) {
      const processedData = processData(paymentData);
      setData(processedData.data);
      setLegends(processedData.legends);
    }
    return () => {
      setData(null);
      setLegends(null);
    };
  }, [indication, addToast, paymentData]);

  return (
    <>
      {data ? (
        <Card className={classes.container}>
          <div className={classes.indicationHeader}>
            <Typography className="name">{indication.name}</Typography>
            <Tooltip>
              <p>
                <strong>Nesta tela</strong> você encontrará todos os{' '}
                <strong>detalhes</strong> a respeito{' '}
                <strong>da sua indicação</strong>.
              </p>
              <p>
                Os <strong>pagamentos disponibilizados</strong> ficarão
                disponíveis <strong>no gráfico</strong>.
              </p>
              <p>
                Acompanhe as <strong>atualizações de status</strong> da sua{' '}
                <strong>indicação</strong>.
              </p>
              <p>
                <strong>Edite ou cadastre contatos</strong> para{' '}
                <strong>ajudar</strong> nosso time comercial{' '}
                <strong>nas vendas</strong>.
              </p>
            </Tooltip>
          </div>
          <div className={classes.indicationDate}>
            <FaRegCalendarCheck />
            <Typography component="span">
              Data da indicação: {getDateBr(indication.created_at)}
            </Typography>
          </div>
          {data && (
            <div className={classes.chartContainer}>
              <div
                className="chart-legend"
                style={
                  legends && legends.length === 1
                    ? { justifyContent: 'center' }
                    : undefined
                }
              >
                {legends &&
                  legends.map((legend) => (
                    <div className="legend-item">
                      <div
                        className="label-circle"
                        style={{ backgroundColor: legend.color }}
                      />
                      <div>
                        <Typography component="div">{legend.title}</Typography>
                        <Typography component="div" className="label-amount">
                          R$ {legend.amount}
                        </Typography>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <Bar
                  data={data}
                  options={{
                    datasets: { bar: { barThickness: 30 } },
                    elements: { bar: { borderRadius: 6 } },
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        callbacks: {
                          title: (tooltipItem: any) => {
                            const index = tooltipItem[0].dataIndex;
                            return tooltipItem[0].dataset.labels[index];
                          },
                          label: (tooltipItem: any) => {
                            return `R$ ${tooltipItem.formattedValue}`;
                          },
                        },
                      },
                    },
                    scales: {
                      y: { grid: { display: true } },
                      x: {
                        grid: { display: false },
                        ticks: {
                          font: {
                            size: 14,
                            weight: 'bold',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </Card>
      ) : null}
    </>
  );
};

export default ChartComponent;
