import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  loader: {
    marginBottom: 20,

    '& .MuiCircularProgress-svg': {
      color: '#fff',
    },
  },
}));
