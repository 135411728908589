import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    '& .floating-btn': {
      position: 'absolute',
      top: 30,
      left: 30,
      display: 'flex',
      alignItems: 'center',
    },

    '& .relative-btn': {
      position: 'relative',
      top: 0,
      left: 0,
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '& .MuiTypography-root': {
      fontSize: '20px',
      fontWeight: 700,
      textTransform: 'none',
      marginLeft: 10,
    },

    '& .dark-btn': {
      color: theme.palette.text.primary,
    },

    '& .light-btn': {
      color: theme.palette.background.paper,
    },
  },
}));
