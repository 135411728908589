import React from 'react';
import { Box, Card, Grid, GridSize, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import ContactEdit from './ContactEdit';
import ContactDelete from './ContactDelete';
import { getDateBr, phoneFormat } from '../../utils/format';
import { IndicationFullDTO } from '../../dtos/IndicationDTO';
import ContactAdd from './ContactAdd';

interface ContactsProps {
  indication: IndicationFullDTO;
  onUpdate: () => void;
}

interface ContactItemProps {
  name: string;
  value: string | null;
}

const setShiftToContact = (shift: number | null): string | null => {
  if (shift === 0) return 'Manhã';
  if (shift === 1) return 'Tarde';
  return null;
};

const ContactItem: React.FC<ContactItemProps> = ({ name, value }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.contactItem}>
      <strong>{name}:</strong> {value || '-'}
    </Typography>
  );
};

const Contacts: React.FC<ContactsProps> = ({ indication, onUpdate }) => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Box className={classes.contactsTitle}>
        <Typography className="text">Informações de contato</Typography>
        {Object.keys(indication).length && indication.contacts.length < 3 ? (
          <ContactAdd onUpdate={onUpdate} indicationId={indication.id} />
        ) : null}
      </Box>
      <Grid container spacing={2}>
        {Object.keys(indication).length > 0 &&
          indication.contacts.map((item) => (
            <Grid
              key={item.id}
              item
              xs={12}
              lg={(12 / indication.contacts.length) as GridSize}
            >
              <Card className={classes.contactCard}>
                <ContactItem name="Nome do contato" value={item.name} />
                <ContactItem name="E-mail" value={item.email} />
                <ContactItem name="Telefone" value={phoneFormat(item.phone)} />
                <ContactItem name="Cargo e função" value={item.occupation} />
                <ContactItem
                  name="Data para contato"
                  value={getDateBr(item.contact_date)}
                />
                <ContactItem
                  name="Turno para contato"
                  value={setShiftToContact(item.shift_to_contact)}
                />
                <ContactItem name="Observação" value={item.observation} />
                <div className={classes.contactFooter}>
                  {(() => {
                    const currentStatusId =
                      indication.status_transactions[0].indication_status_id;
                    return currentStatusId === 99 || currentStatusId === 1 ? (
                      <ContactEdit contact={item} onUpdate={onUpdate} />
                    ) : null;
                  })()}
                  {indication.contacts.length > 1 && (
                    <ContactDelete contact={item} onUpdate={onUpdate} />
                  )}
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Card>
  );
};

export default Contacts;
