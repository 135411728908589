import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';

import { useStyles } from './styles';
import { PartialFormProps } from './props';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import api from '../../services/api';
import { BankDTO } from '../../dtos/BankAccountDTO';

const BankAccountForm: React.FC<PartialFormProps> = ({ formik }) => {
  const [banks, setBanks] = useState<BankDTO[]>([]);
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const classes = useStyles();

  useEffect(() => {
    startLoading();
    api
      .get<BankDTO[]>('/api/banks')
      .then((response) => {
        setBanks(response.data);
      })
      .catch((error) => {
        showApiError(error, 'Erro obtendo a lista de bancos');
      })
      .finally(() => endLoading());
  }, [endLoading, showApiError, startLoading]);

  return (
    <>
      <TextField
        id="bank_id"
        name="bank_id"
        label="Banco"
        select
        className={classes.field}
        variant="outlined"
        fullWidth
        autoComplete="new-bank_id"
        value={formik.values.bank_id}
        error={formik.touched.bank_id && !!formik.errors.bank_id}
        helperText={formik.touched.bank_id && formik.errors.bank_id}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {banks.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="agency"
        name="agency"
        label="Agência"
        className={classes.field}
        variant="outlined"
        fullWidth
        autoComplete="new-agency"
        value={formik.values.agency}
        error={formik.touched.agency && !!formik.errors.agency}
        helperText={formik.touched.agency && formik.errors.agency}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <TextField
        id="account"
        name="account"
        label="Conta"
        className={classes.field}
        variant="outlined"
        fullWidth
        autoComplete="new-account"
        value={formik.values.account}
        error={formik.touched.account && !!formik.errors.account}
        helperText={formik.touched.account && formik.errors.account}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <TextField
        id="account_type"
        name="account_type"
        label="Tipo de conta"
        select
        className={classes.field}
        variant="outlined"
        fullWidth
        autoComplete="new-account_type"
        value={formik.values.account_type}
        error={formik.touched.account_type && !!formik.errors.account_type}
        helperText={formik.touched.account_type && formik.errors.account_type}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="1">Corrente</MenuItem>
        <MenuItem value="2">Poupança</MenuItem>
      </TextField>
    </>
  );
};

export default BankAccountForm;
