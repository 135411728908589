import React from 'react';
import { Grid, Typography, Theme, useMediaQuery } from '@material-ui/core';

import { useStyles } from './styles';
import Search from './Search';
import HelpCard from './HelpCard';
import { pages } from './pagesList';

const Help: React.FC = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography component="div" className={classes.title}>
          Como podemos ajudar?
        </Typography>
        <Search />
      </div>
      <Grid container spacing={matches ? 2 : 0}>
        {pages.map((page) => (
          <Grid key={page} item xs={12} lg={4}>
            <HelpCard page={page} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Help;
