import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    width: '100%',
    marginTop: 'auto',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      bottom: 0,
    },
  },
  container: {
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    borderRadius: 0,
  },
  text: {
    fontSize: '13px',
    color: '#898989',
    textAlign: 'center',
    width: 389,
    maxWidth: '95%',
    margin: '0 auto',

    '& a': {
      color: '#438AFE',
      textDecoration: 'none',
    },
  },
}));
