import React, { useCallback, useState } from 'react';
import { Field } from 'formik';
import { InputAdornment, Typography } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Password: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleFocus = useCallback((element: HTMLInputElement) => {
    setTimeout(() => {
      if (element) element.focus();
    }, 500);
  }, []);

  return (
    <>
      <Typography className="title">Digite a sua senha</Typography>
      <div className="input">
        <Typography className="label">Dica: Use letras e números.</Typography>
        <Field
          name="password"
          type={showPassword ? 'text' : 'password'}
          component={(props: TextFieldProps) => (
            <TextField {...props} inputProps={{ ref: handleFocus }} />
          )}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowPassword((oldState) => !oldState)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="input">
        <Typography className="label">Confirmar senha.</Typography>
        <Field
          name="password_confirmation"
          type={showPasswordConfirm ? 'text' : 'password'}
          component={TextField}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowPasswordConfirm((oldState) => !oldState)}
              >
                {showPasswordConfirm ? <FaEyeSlash /> : <FaEye />}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

export default Password;
