import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaRegCalendarAlt } from 'react-icons/fa';

import { useStyles } from './styles';
import { getIsoDate } from '../../utils/format';
import { emailValidator, phoneValidator } from '../../utils/validators';
import api from '../../services/api';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import { PhoneInput } from '../../components/MaskedInput';

export interface ValuesProps {
  name: string;
  email: string;
  phone: string;
  occupation: string | null;
  contact_date: string | null;
  shift_to_contact: number | null;
  observation: string | null;
}

export interface ContactFormProps {
  id?: number;
  indicationId?: number;
  initialValues: ValuesProps;
  onUpdate: () => void;
}

interface ComponentProps extends ContactFormProps {
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('Email obrigatório')
    .test('Email', 'E-mail inválido', (value) => emailValidator(value)),
  phone: Yup.string()
    .required('Telefone obrigatório')
    .test('Phone', 'Telefone inválido', (value) => phoneValidator(value)),
  occupation: Yup.string().nullable(),
  contact_date: Yup.string().nullable(),
  shift_to_contact: Yup.string().nullable(),
  observation: Yup.string().nullable(),
});

const ContactForm: React.FC<ComponentProps> = ({
  id,
  indicationId,
  initialValues,
  onUpdate,
  onClose,
}) => {
  const [date, setDate] = useState<Date | null>(
    initialValues.contact_date ? new Date(initialValues.contact_date) : null
  );
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      if (date) {
        Object.assign(values, { contact_date: getIsoDate(date) });
      }
      if (indicationId) {
        Object.assign(values, { indication_id: indicationId });
      }

      const baseUrl = '/api/indication-contact';
      const url = id ? `${baseUrl}/${id}` : baseUrl;
      const method = id ? 'put' : 'post';

      startLoading();
      api[method](url, values)
        .then(() => {
          onUpdate();
          onClose();
          addToast({
            message: `Contato ${id ? 'atualizado' : 'criado'}`,
            type: 'success',
          });
        })
        .catch((error) => {
          const message = `Erro ${id ? 'atualizando' : 'criando'} o contato`;
          showApiError(error, message);
        })
        .finally(() => {
          endLoading();
        });
    },
    [
      date,
      indicationId,
      id,
      startLoading,
      onUpdate,
      onClose,
      addToast,
      showApiError,
      endLoading,
    ]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Box marginBottom={2}>
        <Typography variant="h6">
          {id ? 'Edição do' : 'Novo'} contato
        </Typography>
      </Box>
      <TextField
        id="name"
        name="name"
        label="Nome"
        variant="outlined"
        className={classes.field}
        fullWidth
        error={formik.touched.name && !!formik.errors.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        helperText={(formik.touched.name && formik.errors.name) || null}
      />
      <TextField
        id="email"
        name="email"
        label="E-mail"
        variant="outlined"
        className={classes.field}
        fullWidth
        error={formik.touched.email && !!formik.errors.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        helperText={(formik.touched.email && formik.errors.email) || null}
      />
      <TextField
        id="phone"
        name="phone"
        label="Telefone"
        variant="outlined"
        className={classes.field}
        fullWidth
        InputProps={{ inputComponent: PhoneInput as never }}
        error={formik.touched.phone && !!formik.errors.phone}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.phone}
        helperText={(formik.touched.phone && formik.errors.phone) || null}
      />
      <TextField
        id="occupation"
        name="occupation"
        label="Cargo e função"
        variant="outlined"
        className={classes.field}
        fullWidth
        error={formik.touched.occupation && !!formik.errors.occupation}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.occupation}
        helperText={
          (formik.touched.occupation && formik.errors.occupation) || null
        }
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <DatePicker
          name="contact_date"
          disablePast
          clearable
          inputVariant="outlined"
          className={classes.field}
          format="dd/MM/yyyy"
          label="Data para contato:"
          value={date}
          fullWidth
          onChange={setDate}
          cancelLabel={false}
          okLabel="Confirmar"
          clearLabel="Limpar"
          InputProps={{
            endAdornment: (
              <IconButton>
                <FaRegCalendarAlt />
              </IconButton>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
      <FormControl variant="outlined" fullWidth className={classes.field}>
        <InputLabel>Turno para contato</InputLabel>
        <Select
          id="shift_to_contact"
          name="shift_to_contact"
          label="Turno para contato"
          fullWidth
          error={
            formik.touched.shift_to_contact && !!formik.errors.shift_to_contact
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.shift_to_contact || ''}
        >
          <MenuItem value="" />
          <MenuItem value="0">Manhã</MenuItem>
          <MenuItem value="1">Tarde</MenuItem>
        </Select>
        <FormHelperText>
          {(formik.touched.shift_to_contact &&
            formik.errors.shift_to_contact) ||
            null}
        </FormHelperText>
      </FormControl>
      <TextField
        id="observation"
        name="observation"
        label="Observação"
        variant="outlined"
        className={classes.field}
        fullWidth
        error={formik.touched.observation && !!formik.errors.observation}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.observation}
        helperText={
          (formik.touched.observation && formik.errors.observation) || null
        }
      />
      <Box marginY={2} display="flex" justifyContent="center">
        <Button type="submit" color="primary" variant="contained">
          Enviar
        </Button>
      </Box>
    </form>
  );
};

export default ContactForm;
