import React, { useCallback, useState } from 'react';
import { Box, Card } from '@material-ui/core';
import { useParams } from 'react-router';

import { useStyles } from './styles';
import { SubmitProps } from './props';
import PersonalDataForm from './PersonalDataForm';
import AddressForm from './AddressForm';
import Payment from './Payment';
import AvatarEdition from './AvatarEdition';
import EditConfirmation from './EditConfirmation';
import BackLink from '../../components/BackLink';

export type ProfileSection = 'dados' | 'endereco' | 'conta' | 'avatar';
export type Editing = ProfileSection | null;

const ProfileEdit: React.FC = () => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [state, setState] = useState<SubmitProps>({} as SubmitProps);
  const { section } = useParams<{ section: ProfileSection }>();
  const classes = useStyles();

  const handleSubmit = useCallback((data: SubmitProps) => {
    setState(data);
    setConfirmationOpen(true);
  }, []);

  return (
    <>
      <Box className={classes.backBtn}>
        <BackLink floating={false} color="dark" />
      </Box>
      <Card className={classes.formContainer}>
        {section === 'dados' && <PersonalDataForm onSubmit={handleSubmit} />}
        {section === 'endereco' && <AddressForm onSubmit={handleSubmit} />}
        {section === 'conta' && <Payment onSubmit={handleSubmit} />}
        {section === 'avatar' && <AvatarEdition onSubmit={handleSubmit} />}
        <EditConfirmation
          {...state}
          open={confirmationOpen}
          onClose={() => setConfirmationOpen(false)}
        />
      </Card>
    </>
  );
};

export default ProfileEdit;
