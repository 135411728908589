/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IApiError {
  data: any;
  status?: number;
}

const defaultValues: { [key: number]: string } = {
  400: 'Erro na solicitação ao servidor',
  401: 'Erro de autenticação',
  403: 'Solicitação não autorizada',
  404: 'Recurso não encontrado',
  422: 'Erro de validação',
  500: 'Erro interno do servidor',
};

export default class ApiError implements IApiError {
  public readonly data: any;

  public readonly status: number;

  constructor(data: any, status?: number) {
    this.data = data;
    this.status = status || 500;
  }

  public getMessage(): string | string[] {
    if (
      this.status === 422 &&
      typeof this.data === 'object' &&
      Object.hasOwnProperty.call(this.data, 'errors')
    ) {
      const messagesList: string[] = [];
      const errors = this.data.errors as { [key: string]: string[] };
      Object.values(errors).forEach((item) => {
        item.forEach((error) => messagesList.push(error));
      });
      return messagesList;
    }

    if (
      typeof this.data === 'object' &&
      Object.hasOwnProperty.call(this.data, 'message') &&
      this.data.message !== ''
    ) {
      return String(this.data.message);
    }

    return defaultValues[this.status];
  }
}
