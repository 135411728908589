import React from 'react';
import { Typography } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import { Field } from 'formik';

const Observation: React.FC = () => {
  return (
    <>
      <Typography className="title">Alguma observação?</Typography>
      <div className="input">
        <Typography className="label">
          Ex.: Melhor data e turno para contato, horário de funcionamento.
        </Typography>
        <Field
          name="observation"
          component={(props: TextFieldProps) => (
            <TextField
              {...props}
              multiline
              InputProps={{
                ref: (element: HTMLDivElement) => {
                  if (element) {
                    const textArea = element.querySelector('textarea');
                    if (textArea) textArea.focus();
                  }
                },
              }}
            />
          )}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default Observation;
