import React, { useCallback } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { FormProps } from './props';
import { CpfInput, PhoneInput } from '../../components/MaskedInput';
import { useAuth } from '../../context/auth';
import { cleanNumericString } from '../../utils/format';
import { phoneValidator } from '../../utils/validators';

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('O nome é obrigatório'),
  lastname: Yup.string().required('O sobrenome é obrigatório'),
  phone: Yup.string()
    .required('O telefone é obrigatório')
    .test('phone', 'Telefone inválido', phoneValidator),
  password: Yup.string().min(8, 'Mínimo de 8 caracteres'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas não coincidem'
  ),
});

const PersonalDataForm: React.FC<FormProps> = ({ onSubmit }) => {
  const { user } = useAuth();
  const classes = useStyles();

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const data = {
        firstname: values.firstname,
        lastname: values.lastname,
        phone: cleanNumericString(values.phone),
        password: values.password,
        password_confirmation: values.password_confirmation,
      };

      onSubmit({
        data,
        method: 'put',
        url: `/api/user/${user.id}`,
        successMessage: 'Cadastro atualizado com sucesso',
        errorMessage: 'Erro atualizando seu cadastro',
      });
    },
    [user, onSubmit]
  );

  const formik = useFormik({
    initialValues: {
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone || '',
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Typography className={classes.formTitle}>Meus dados</Typography>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <TextField
          id="firstname"
          name="firstname"
          label="Primeiro Nome"
          className={classes.field}
          variant="outlined"
          fullWidth
          value={formik.values.firstname}
          error={formik.touched.firstname && !!formik.errors.firstname}
          helperText={formik.touched.firstname && formik.errors.firstname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          id="lastname"
          name="lastname"
          label="Sobrenome"
          className={classes.field}
          variant="outlined"
          fullWidth
          value={formik.values.lastname}
          error={formik.touched.lastname && !!formik.errors.lastname}
          helperText={formik.touched.lastname && formik.errors.lastname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          name="document"
          disabled
          variant="outlined"
          fullWidth
          InputProps={{ inputComponent: CpfInput as any }}
          value={user.document}
          className={classes.field}
        />
        <TextField
          name="email"
          disabled
          variant="outlined"
          fullWidth
          value={user.email}
          className={classes.field}
        />
        <TextField
          id="phone"
          name="phone"
          label="Telefone"
          className={classes.field}
          variant="outlined"
          fullWidth
          InputProps={{ inputComponent: PhoneInput as any }}
          value={formik.values.phone}
          error={formik.touched.phone && !!formik.errors.phone}
          helperText={formik.touched.phone && formik.errors.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          id="password"
          name="password"
          type="password"
          label="Senha"
          className={classes.field}
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          value={formik.values.password}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          label="Repetir a senha"
          className={classes.field}
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          value={formik.values.password_confirmation}
          error={
            formik.touched.password_confirmation &&
            !!formik.errors.password_confirmation
          }
          helperText={
            formik.touched.password_confirmation &&
            formik.errors.password_confirmation
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Box marginTop={3} textAlign="center">
          <Button type="submit" variant="contained" color="primary">
            Salvar alterações
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PersonalDataForm;
