import React from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import { NewIndicationFormData } from './props';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.paper,
    '& .MuiIconButton-root': {
      color: theme.palette.background.paper,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      color: '#85B0FF',
    },
  },
  title: {
    fontSize: '25px',
    fontWeight: 600,
    marginBottom: '20px',
  },
  label: {
    fontSize: '15px',
    color: '#85B0FF',
    marginBottom: '15px',
  },
  value: {
    fontSize: '15px',
    fontWeight: 600,
  },
  field: {
    paddingTop: 25,
  },
  fieldError: {
    fontSize: '12px',
    color: theme.palette.error.main,
  },
}));

const Confirm: React.FC = () => {
  const { values } = useFormikContext<NewIndicationFormData>();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Confirme seus dados</Typography>
      <Grid container className="text">
        <Grid item xs={4}>
          <Typography className={classes.label}>Nome:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>{values.name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>Site:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>{values.site}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>CNPJ:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>{values.document}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>Contato:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>
            {values.contactName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>E-mail:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>
            {values.contactEmail}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>Telefone:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>
            {values.contactPhone}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label}>Observações:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.value}>
            {values.observation}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.field}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="consent"
            Label={{
              label: 'Assumo a responsabilidade pelos dados inseridos.',
            }}
          />
          <ErrorMessage
            component="span"
            name="consent"
            className={classes.fieldError}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Confirm;
