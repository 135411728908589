import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FaEye } from 'react-icons/fa';

import { useStyles } from './styles';
import IndicationStatus from '../../components/IndicationStatus';
import Tooltip from '../../components/Tooltip';
import { IndicationDTO } from '../../dtos/IndicationDTO';

interface IndicationsTableProps {
  data: IndicationDTO[];
}

const IndicationsTable: React.FC<IndicationsTableProps> = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.indicationsTableContainer}>
      <div className="title">
        <div>
          <Typography className="title-text">Últimas Indicações</Typography>
          <Tooltip>
            <p>
              <strong>Aqui</strong> você confere as suas{' '}
              <strong>últimas indicações realizadas</strong>.
            </p>
            <p>
              <strong>Ao lado de cada indicação</strong> (ícone do olho) você
              poderá <strong>clicar para visualizar detalhes</strong>.
            </p>
            <p>
              Para <strong>visualizar TODAS suas indicações</strong> clique no{' '}
              <strong>botão &quot;Ver mais&quot;</strong>.
            </p>
          </Tooltip>
        </div>
      </div>
      {data.length === 0 ? (
        <Typography className="empty-msg">
          Você ainda não realizou nenhuma indicação. Comece a indicar{' '}
          <Link to="/indicar">agora</Link>
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.indicationsTable}>
            <TableHead>
              <TableRow>
                <TableCell>Indicados</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="center">
                    <IndicationStatus transactions={item.status_transactions} />
                  </TableCell>
                  <TableCell align="center">
                    <Card className="circle-card" variant="elevation">
                      <IconButton
                        className="circle-button"
                        onClick={() =>
                          history.push('/indicacao', {
                            indicationId: item.id,
                            origin: '/',
                          })
                        }
                      >
                        <FaEye size={15} />
                      </IconButton>
                    </Card>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <div className="btn">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => history.push('/indicacoes')}
                    >
                      Ver mais
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Card>
  );
};

export default IndicationsTable;
