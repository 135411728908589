import React, { useState } from 'react';
import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';

import { useStyles } from './styles';
import Menu from '../Menu';
import Avatar from '../../../components/Avatar';
import { ReactComponent as LogoH } from '../../../assets/logo_h.svg';
import { ReactComponent as MenuIcon } from '../../../assets/menu.svg';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              onClick={() => setIsOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <LogoH />
          <Hidden smDown>
            <div className={classes.userSection}>
              <Avatar size={40} border={2} borderColor="white" />
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Header;
