import React from 'react';
import { IconButton, Tooltip as MuiTooltip } from '@material-ui/core';
import { FaInfoCircle } from 'react-icons/fa';

import { useStyles } from './styles';

interface TooltipProps {
  children: React.ReactFragment;
  onClick?: () => void;
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const Tooltip: React.FC<TooltipProps> = ({ children, position, onClick }) => {
  const classes = useStyles();

  return (
    <MuiTooltip title={children} arrow placement={position || 'top'}>
      <IconButton onClick={onClick} className={classes.iconButton}>
        <FaInfoCircle className={classes.tooltip} />
      </IconButton>
    </MuiTooltip>
  );
};

export default Tooltip;
