/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { PageName } from './pagesList';
import BackLink from '../../components/BackLink';

interface ContentProps {
  component: 'p' | 'ol' | 'ul';
  content?: string;
  items?: string[];
}

export interface HelpFileProps {
  title: string;
  subtitle: string;
  icon: string;
  contents: ContentProps[];
}

const Paragraph: React.FC<{ content: ContentProps }> = ({ content }) => (
  <Typography>{content.content}</Typography>
);

const List: React.FC<{ content: ContentProps }> = ({ content }) => (
  <Box component={content.component}>
    {content.items?.map((item) => (
      <Typography component="li" key={item}>
        {item}
      </Typography>
    ))}
  </Box>
);

const HelpView: React.FC = () => {
  const [fileContent, setFileContent] = useState<HelpFileProps | null>(null);
  const [icon, setIcon] = useState('');
  const classes = useStyles();
  const { page } = useParams<{ page: PageName }>();

  useEffect(() => {
    const file = require(`./${page}.json`) as HelpFileProps;
    setFileContent(file);
    if (file.icon) {
      const importIcon = async (): Promise<void> => {
        try {
          const result = await import(`../../assets/help/${file.icon}.svg`);
          setIcon(result.default);
        } catch (err) {
          console.error(err);
        }
      };
      importIcon();
    }

    return () => setFileContent(null);
  }, [page]);

  return (
    <div className={classes.helpPageContainer}>
      <BackLink color="dark" />
      {fileContent ? (
        <Card className={classes.pageCard}>
          <div className="header">
            {icon && <img src={icon} alt="" />}
            <div>
              <Typography className="title">{fileContent.title}</Typography>
              <Typography className="subtitle">
                {fileContent.subtitle}
              </Typography>
            </div>
          </div>
          {fileContent.contents.map((item) => {
            const Component = item.component === 'p' ? Paragraph : List;
            return <Component content={item} />;
          })}
        </Card>
      ) : null}
    </div>
  );
};

export default HelpView;
