import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import { MdFilterList } from 'react-icons/md';
import { FaTimes, FaRegCalendarAlt } from 'react-icons/fa';

import { useStyles } from './styles';
import { useIndicationStatus } from '../../context/indicationStatus';

export interface FiltersProps {
  startDate: Date | null;
  endDate: Date | null;
  status: number | '';
  name: string | null;
}

interface ComponentProps {
  filters: FiltersProps;
  onFilter: (filters: FiltersProps) => void;
}

const Filters: React.FC<ComponentProps> = ({ filters, onFilter }) => {
  const [status, setStatus] = useState(filters.status);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [name, setName] = useState(filters.name);
  const [isOpen, setIsOpen] = useState(false);
  const { statuses, getStatusList } = useIndicationStatus();
  const classes = useStyles();

  useEffect(() => {
    if (statuses.length === 0) {
      getStatusList();
    }
  }, [statuses, getStatusList]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onFilter({
        status,
        startDate,
        endDate,
        name,
      });
      setIsOpen(false);
    },
    [status, startDate, endDate, name, onFilter]
  );

  return (
    <div>
      <IconButton color="primary" size="small" onClick={handleOpen}>
        <MdFilterList size={20} />
      </IconButton>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          className={classes.filtersContainer}
        >
          <div>
            <IconButton
              onClick={handleClose}
              className={classes.filtersCloseBtn}
            >
              <FaTimes />
            </IconButton>
          </div>
          <DialogContent>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Typography className={classes.filtersTitle}>
                Filtrar por Status:
              </Typography>
              <Select
                name="status"
                id="status"
                fullWidth
                value={status}
                onChange={(ev) => setStatus(ev.target.value as number)}
              >
                <MenuItem value="">Todos</MenuItem>
                {statuses.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name_app}
                  </MenuItem>
                ))}
              </Select>
              <Typography className={classes.filtersTitle}>
                Filtrar por Data:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    name="startDate"
                    disableFuture
                    clearable
                    format="dd/MM/yyyy"
                    label="Data Inicio:"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    cancelLabel={false}
                    okLabel="Confirmar"
                    clearLabel="Limpar"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <FaRegCalendarAlt />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    name="endDate"
                    disableFuture
                    clearable
                    format="dd/MM/yyyy"
                    label="Data Final:"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    cancelLabel={false}
                    okLabel="Confirmar"
                    clearLabel="Limpar"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <FaRegCalendarAlt />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Typography className={classes.filtersTitle}>
                Filtrar por Razão Social:
              </Typography>
              <TextField
                name="name"
                label="Razão Social"
                className={classes.filtersField}
                variant="standard"
                fullWidth
                onChange={(ev) => setName(ev.target.value)}
                value={name}
              />
              <div className={classes.btnContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.filtersBtn}
                >
                  Filtrar
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default Filters;
