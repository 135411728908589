import React from 'react';
import { Card, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { FaClock } from 'react-icons/fa';

import { useStyles } from './styles';
import { IndicationFullDTO } from '../../dtos/IndicationDTO';

interface TimelineProps {
  indication: IndicationFullDTO;
}

const TimelineComponent: React.FC<TimelineProps> = ({ indication }) => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Typography className={classes.historyTitle}>Atualizações</Typography>
      <Timeline className={classes.timeline}>
        {Object.keys(indication).length > 0 &&
          indication.status_transactions.map((item, idx, items) => (
            <TimelineItem key={item.id}>
              <TimelineSeparator>
                <TimelineDot
                  style={{
                    backgroundColor: `#${item.indication_status.color}`,
                  }}
                />
                {idx !== items.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <Typography className={classes.statusName}>
                  {item.indication_status.name_app}
                </Typography>
                <div className={classes.statusDate}>
                  <FaClock />
                  <Typography>
                    {new Date(item.created_at).toLocaleString('pt-BR', {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </Card>
  );
};

export default TimelineComponent;
