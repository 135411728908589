import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import api from '../../services/api';
import { useToast } from '../../context/toast';
import { useErrorHandle } from '../../context/errorHandle';

interface EmailConfirmationParams {
  id: string;
  hash: string;
}

const EmailConfirmation: React.FC = () => {
  const { id, hash } = useParams<EmailConfirmationParams>();
  const { search } = useLocation();
  const history = useHistory();
  const { addToast } = useToast();
  const { showApiError } = useErrorHandle();
  const classes = useStyles();

  useEffect(() => {
    const url = `/api/auth/verify_email/${id}/${hash}${search}`;
    api
      .get(url)
      .then((result) => {
        addToast({ message: result.data.message, type: 'success' });
        history.push('/');
      })
      .catch((error) => {
        showApiError(error, 'Erro validando seu e-mail');
      });
  }, [addToast, hash, history, id, search, showApiError]);

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
      <Typography>Verificando seu e-mail...</Typography>
    </div>
  );
};

export default EmailConfirmation;
