import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';

import { useStyles } from './styles';
import Withdraw from './Withdraw';
import Summary from './Summary';
import Chart from './Chart';
import Table from './Table';
import api from '../../services/api';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { WithdrawChartDTO, WithdrawPaymentDTO } from '../../dtos/WithdrawDTO';
import { ListDTO } from '../../dtos/ResultsDTO';

interface TableDataProps {
  data: WithdrawPaymentDTO[];
  page: number;
  perPage: string;
  total: number;
}

const initialTableData: TableDataProps = {
  data: [],
  page: 0,
  perPage: '10',
  total: 0,
};

const Earnings: React.FC = () => {
  const [withdraw, setWithdraw] = useState<number>(0);
  const [chartData, setChartData] = useState<WithdrawChartDTO[]>([]);
  const [tableData, setTableData] = useState<TableDataProps>(initialTableData);
  const [tableLoading, setTableLoading] = useState(false);
  const [version, setVersion] = useState(0);
  const [months, setMonths] = useState(3);
  const [totalPaid, setTotalPaid] = useState<number | null>(null);
  const [totalWaiting, setTotalWaiting] = useState<number | null>(null);
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();

  const getWithdraw = useCallback(async () => {
    try {
      const result = await api.get<{ total: number }>(
        '/api/payment-reports/total-available'
      );
      setWithdraw(result.data.total);
    } catch (error) {
      showApiError(error, 'Erro procurando os dados');
    }
    return () => setWithdraw(0);
  }, [showApiError]);

  const getChartData = useCallback(async () => {
    try {
      const result = await api.get<WithdrawChartDTO[]>(
        '/api/payment-reports/my-financial-gains-chart',
        { params: { months } }
      );
      setChartData(result.data);
    } catch (error) {
      showApiError(error, 'Não foi possível obter os dados do gráfico');
    }
  }, [months, showApiError]);

  const getTotalWaiting = useCallback(async () => {
    try {
      const result = await api.get<{ total: number }>(
        '/api/payment-reports/total-requested'
      );
      setTotalWaiting(result.data.total);
    } catch (error) {
      showApiError(error, 'Não foi possível obter o saldo solicitado');
    }
  }, [showApiError]);

  const getTotalPaid = useCallback(async () => {
    try {
      const result = await api.get<{ total: number }>(
        '/api/payment-reports/total-paid'
      );
      setTotalPaid(result.data.total);
    } catch (error) {
      showApiError(error, 'Não foi possível obter o total pagado');
    }
  }, [showApiError]);

  const getTableData = useCallback(async () => {
    setTableLoading(true);
    try {
      const result = await api.get<ListDTO<WithdrawPaymentDTO>>(
        '/api/withdraw-payments',
        { params: { page: tableData.page, limit: tableData.perPage } }
      );
      setTableData({
        data: result.data.data,
        total: result.data.total,
        page: result.data.current_page,
        perPage: result.data.per_page,
      });
      setTableLoading(false);
    } catch (error) {
      showApiError(error, 'Não foi possível obter os dados dos saques');
      setTableLoading(false);
    }
  }, [showApiError, tableData.page, tableData.perPage]);

  const handleTableNextPage = useCallback(() => {
    setTableData((oldState) => ({ ...oldState, page: oldState.page }));
  }, []);

  useEffect(() => {
    startLoading();
    Promise.all([
      getWithdraw(),
      getChartData(),
      getTotalWaiting(),
      getTotalPaid(),
      getTableData(),
    ]).then(() => {
      endLoading();
    });
  }, [
    startLoading,
    endLoading,
    version,
    getWithdraw,
    getChartData,
    getTotalWaiting,
    getTotalPaid,
    getTableData,
  ]);

  const handleUpdate = useCallback(() => {
    setVersion(version + 1);
  }, [version]);

  return (
    <Grid container className={classes.gridContainer} spacing={matches ? 2 : 0}>
      <Grid item xs={12} lg={6} xl={4} className="info">
        <Withdraw withdraw={withdraw} onUpdate={handleUpdate} />
      </Grid>
      <Grid item xs={12} lg={12} xl={4} className="summary">
        <Summary totalPaid={totalPaid} totalWaiting={totalWaiting} />
      </Grid>
      <Grid item xs={12} lg={6} xl={4} className="chart">
        <Chart
          months={months}
          withdraws={chartData}
          onMonthsChange={setMonths}
        />
      </Grid>
      <Grid item xs={12} className="table">
        <Table
          data={tableData.data}
          total={tableData.total}
          loading={tableLoading}
          onPageChange={handleTableNextPage}
        />
      </Grid>
    </Grid>
  );
};

export default Earnings;
