import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PrivacyContent from '../partials/Legal/Privacy';

const useStyles = makeStyles({
  container: {
    padding: '15px 44px',
  },
});

const Privacy: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PrivacyContent />
    </div>
  );
};

export default Privacy;
