import React from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FaEye } from 'react-icons/fa';

import { useStyles } from './styles';
import IndicationStatus from '../../components/IndicationStatus';
import { IndicationDTO } from '../../dtos/IndicationDTO';

export interface TableProps {
  data: IndicationDTO[];
  total: number;
  next: () => void;
  loading?: boolean;
}

const TableComponent: React.FC<TableProps> = ({
  data,
  total,
  next,
  loading,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={next}
      hasMore={data.length < total}
      loader={<Typography>Carregando...</Typography>}
    >
      <TableContainer>
        {loading && data.length === 0 ? (
          <CircularProgress size={50} />
        ) : (
          <Table className={classes.indicationsTable}>
            <TableHead>
              <TableRow>
                <TableCell>Indicados</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>Sem resultados</TableCell>
                </TableRow>
              ) : (
                data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="center">
                      <IndicationStatus
                        transactions={item.status_transactions}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Card className="circle-card" variant="elevation">
                        <IconButton
                          className="circle-button"
                          onClick={() =>
                            history.push('/indicacao', {
                              indicationId: item.id,
                            })
                          }
                        >
                          <FaEye size={15} />
                        </IconButton>
                      </Card>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </InfiniteScroll>
  );
};

export default TableComponent;
