import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    },
    '& .info': { order: 1 },
    '& .summary': {
      order: 2,
      [theme.breakpoints.only('lg')]: {
        order: 3,
      },
    },
    '& .chart': {
      order: 3,
      [theme.breakpoints.only('lg')]: {
        order: 2,
      },
    },
    '& .table': { order: 4 },
  },
  container: {
    background: theme.palette.background.paper,
    width: 389,
    maxWidth: '95%',
    padding: '40px 0',
    marginTop: 25,
    marginBottom: 15,
    [theme.breakpoints.only('md')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: 'unset',
      maxWidth: 'unset',
      margin: 0,
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    '& svg': {
      marginLeft: 10,
      color: '#BCBCBC',
    },
  },
  currentMonth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(139deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark})`,
    color: theme.palette.background.paper,
    padding: '60px 0',
    margin: '30px 18px',
    '& .earnings-container': {
      marginLeft: 20,
    },
    '& .earnings-text': {
      fontSize: '12px',
    },
    '& .month-earnings': {
      fontSize: '30px',
      fontWeight: 700,
    },
  },
  sendButton: {
    margin: '0 30px 25px',
    '& svg': {
      marginLeft: 8,
    },
  },
  minimalWithdraw: {
    textAlign: 'center',
    fontSize: '14px',
  },
  textObservation: {
    fontSize: '11px',
  },
  rangeBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  rangeBtn: {
    fontSize: '15px',
    textTransform: 'none',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 40,
    flex: 1,
    [theme.breakpoints.only('lg')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    '& .summary-title': {
      fontSize: '18px',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '& .summary-item': {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.only('lg')]: {
        marginLeft: 15,
        justifyContent: 'center',
      },
      '& svg': {
        marginRight: 18,
      },
    },
    '& .summary-type': {
      fontSize: '16px',
    },
    '& .summary-amount': {
      fontSize: '17px',
      fontWeight: 700,
    },
  },
  chartContainer: {
    margin: '20px auto 0',
    width: '90%',
    height: '100%',
    border: '2px solid #F5F5F5',
    borderRadius: 5,
    '& > div': {
      padding: 18,
    },
    '& .chart-legend': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '2px solid #F5F5F5',
      '& .legend-item': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .label-circle': {
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          marginRight: 8,
        },
        '& .label-title': {
          fontSize: 14,
        },
        '& .label-amount': {
          fontSize: 15,
          fontWeight: 700,
          lineHeight: 1,
        },
      },
    },
  },
  chartAreaContainer: {
    minHeight: 200,
  },
  indicationsTable: {
    marginBottom: 0,

    '& .MuiTableHead-root': {
      background: theme.palette.primary.main,

      '& .MuiTableCell-head': {
        color: theme.palette.background.paper,
        fontSize: '13px',
        fontWeight: 700,
      },
    },

    '& .MuiTableCell-root': {
      fontSize: '12px',
      fontWeight: 600,
      padding: 8,
    },
  },
  circleCard: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .circle-button': {
      color: theme.palette.primary.main,
      padding: 0,
    },
  },
  closeBtn: {
    float: 'right',
    margin: 10,
    color: '#000',
  },
  detailsContainer: {
    position: 'relative',
    '& .withdrawId': {
      position: 'absolute',
      top: 15,
      left: 15,
      color: '#777777',
      fontSize: '13px',
    },
    '& .MuiDialog-paper': {
      paddingBottom: 20,
    },
    '& .MuiDialogContent-root': {
      marginTop: 30,
    },
    '& .detailsTableHeader': {
      textAlign: 'center',
      background: theme.palette.primary.main,
      color: theme.palette.background.paper,
      padding: 16,
      '& > div': {
        fontSize: '32px',
        fontWeight: 700,
        marginBottom: 6,
      },
      '& > p': { fontSize: '13px' },
    },
    '& .MuiTableCell-root': {
      fontSize: '13px',
    },
    '& .installment': {
      color: '#AEADAF',
    },
  },
  noBankAccountDialog: {
    '& .MuiDialog-paper': {
      padding: '40px 30px',
    },
    '& .btnContainer': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 40,
      '& .MuiButton-root': {
        padding: '10px 25px',
      },
    },
  },
}));
