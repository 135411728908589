import React, { useCallback } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { FormProps } from './props';
import BankAccountForm from './BankAccountForm';
import PixForm from './PixForm';
import { useAuth } from '../../context/auth';
import { phoneValidator, cpfValidator } from '../../utils/validators';
import AccountOwnerForm from './AccountOwnerForm';

const validationSchema = Yup.object().shape({
  account_owner_is_user: Yup.boolean(),
  transfer_type: Yup.string(),
  bank_id: Yup.number().when('transfer_type', {
    is: '2',
    then: Yup.number().required('O banco é obrigatório'),
  }),
  agency: Yup.string().when('transfer_type', {
    is: '2',
    then: Yup.string().required('A agência é obrigatória'),
  }),
  account: Yup.string().when('transfer_type', {
    is: '2',
    then: Yup.string().required('O número da conta é obrigatório'),
  }),
  account_type: Yup.number().when('transfer_type', {
    is: '2',
    then: Yup.number().required('O tipo de conta é obrigatório'),
  }),
  pix_type: Yup.number().when('transfer_type', {
    is: '1',
    then: Yup.number().required('O tipo de chave é obrigatório'),
  }),
  pix_key: Yup.string().when('transfer_type', {
    is: '1',
    then: Yup.string().required('A chave é obrigatória'),
  }),
  account_owner_fisrtname: Yup.string()
    .nullable()
    .when('account_owner_is_user', {
      is: false,
      then: Yup.string().required('Nome obrigatório'),
    }),
  account_owner_lastname: Yup.string()
    .nullable()
    .when('account_owner_is_user', {
      is: false,
      then: Yup.string().required('Sobrenome obrigatório'),
    }),
  account_owner_document: Yup.string()
    .nullable()
    .when('account_owner_is_user', {
      is: false,
      then: Yup.string()
        .required('CPF obrigatório')
        .test('cpf', 'Formato do CPF errado', cpfValidator),
    }),
  account_owner_phone: Yup.string().when('account_owner_is_user', {
    is: false,
    then: Yup.string()
      .required('Telefone obrigatório')
      .test('phone', 'Formato de telefone errado', phoneValidator),
  }),
});

const Payment: React.FC<FormProps> = ({ onSubmit }) => {
  const { user } = useAuth();
  const classes = useStyles();

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      let url = '/api/bank-account';
      let method: 'post' | 'put' = 'post';
      if (user.bank_account_id) {
        url += `/${user.bank_account_id}`;
        method = 'put';
      }

      const data: { [key: string]: unknown } = {};
      Object.entries(values).forEach(([key, value]) => {
        if (value !== '') {
          if (['transfer_type', 'pix_type', 'account_type'].includes(key)) {
            data[key] = parseInt(value, 10);
            return;
          }
          if (key === 'account_owner_is_user') {
            data[key] = !!value;
          }
          data[key] = value;
        }
      });

      onSubmit({
        data,
        url,
        method,
        successMessage: 'Dados bancários atualizados com sucesso',
        errorMessage: 'Erro atualizando os dados bancários',
      });
    },
    [onSubmit, user]
  );

  const formik = useFormik({
    initialValues: {
      account_owner_is_user: user.bank_account
        ? user.bank_account.account_owner_is_user
        : true,
      transfer_type: user.bank_account
        ? user.bank_account.transfer_type.toString()
        : '1',
      bank_id: user.bank_account ? user.bank_account.bank_id || '' : '',
      agency: user.bank_account ? user.bank_account.agency || '' : '',
      account: user.bank_account ? user.bank_account.account || '' : '',
      account_type: user.bank_account
        ? user.bank_account.account_type || ''
        : '',
      pix_type: user.bank_account ? user.bank_account.pix_type || '' : '',
      pix_key: user.bank_account ? user.bank_account.pix_key || '' : '',
      account_owner_fisrtname: user.bank_account
        ? user.bank_account.account_owner_fisrtname || ''
        : '',
      account_owner_lastname: user.bank_account
        ? user.bank_account.account_owner_lastname || ''
        : '',
      account_owner_document: user.bank_account
        ? user.bank_account.account_owner_document || ''
        : '',
      account_owner_phone: user.bank_account
        ? user.bank_account.account_owner_phone || ''
        : '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Typography className={classes.formTitle}>Dados bancários</Typography>
      <Typography align="center">Deseja receber pagamento por:</Typography>
      <RadioGroup
        row
        name="transfer_type"
        className={classes.paymentSelect}
        value={formik.values.transfer_type}
        onChange={formik.handleChange}
      >
        <FormControlLabel
          value="1"
          checked={formik.values.transfer_type === '1'}
          control={<Radio color="primary" />}
          label="PIX"
        />
        <FormControlLabel
          value="2"
          checked={formik.values.transfer_type === '2'}
          control={<Radio color="primary" />}
          label="DOC / TED"
        />
      </RadioGroup>
      {formik.values.transfer_type === '1' ? (
        <PixForm formik={formik} />
      ) : (
        <BankAccountForm formik={formik} />
      )}
      <AccountOwnerForm formik={formik} />
      <Box marginTop={3} textAlign="center">
        <Button type="submit" variant="contained" color="primary">
          Salvar alterações
        </Button>
      </Box>
    </form>
  );
};

export default Payment;
