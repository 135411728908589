import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  backBtn: {
    width: 389,
    maxWidth: '95%',
    textAlign: 'left',
    margin: 30,
  },
  pageContainer: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1500,
    },
  },
  container: {
    background: theme.palette.background.paper,
    width: 389,
    maxWidth: '95%',
    padding: 44,
    margin: '0 auto 30px',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: '100%',
      margin: 0,
      marginBottom: 30,
    },
  },
  statusContainer: {
    color: '#fff',
    textShadow: '1px 1px 4px rgba(0, 0, 0, 0.8)',
  },
  statusTitle: {
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  statusText: {
    fontSize: '26px',
    fontWeight: 700,
  },
  indicationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #A098AE',

    '& .name': {
      fontSize: '20px',
      fontWeight: 700,
    },
  },
  indicationDate: {
    margin: '25px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    '& span': {
      fontSize: '12px',
      marginLeft: '5px',
    },
  },
  chartContainer: {
    border: '2px solid #F5F5F5',
    borderRadius: 5,
    '& > div': {
      padding: 18,
    },
    '& .chart-legend': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '2px solid #F5F5F5',
      '& .legend-item': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .label-circle': {
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          marginRight: 8,
        },
        '& .label-amount': {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: 1,
        },
      },
    },
  },
  historyTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  timeline: {
    paddingLeft: 0,
    '& .MuiTimelineItem-root::before': {
      flex: 0,
      padding: 0,
    },
    '& .MuiTimelineContent-root': {
      paddingTop: 3,
    },
  },
  timelineContent: {
    paddingTop: 0,
  },
  statusName: {
    fontSize: '15px',
    fontWeight: 700,
  },
  statusDate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: '#A098AE',
    '& svg': {
      marginRight: 5,
      color: '#6E6E6E',
    },
  },
  contactsTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  circleCard: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleButton: {
    color: '#252525',
    padding: 0,
    fontSize: 20,
  },
  contactCard: {
    padding: 20,
    marginTop: 25,
  },
  contactItem: {
    fontSize: '13px',
    marginBottom: 10,
  },
  contactFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    float: 'right',
    margin: 10,
    color: '#000',
  },
  field: {
    marginBottom: 15,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 20px 30px',
  },
}));
