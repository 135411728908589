import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

import { useStyles } from './styles';
import { PartialFormProps } from './props';

const PixForm: React.FC<PartialFormProps> = ({ formik }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        id="pix_type"
        name="pix_type"
        label="Tipo de chave"
        select
        className={classes.field}
        variant="outlined"
        fullWidth
        autoComplete="new-pix_type"
        value={formik.values.pix_type}
        error={formik.touched.pix_type && !!formik.errors.pix_type}
        helperText={formik.touched.pix_type && formik.errors.pix_type}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="1">Telefone</MenuItem>
        <MenuItem value="2">CPF</MenuItem>
        <MenuItem value="3">E-mail</MenuItem>
      </TextField>
      <TextField
        id="pix_key"
        name="pix_key"
        label="Chave"
        className={classes.field}
        variant="outlined"
        fullWidth
        autoComplete="new-pix_key"
        value={formik.values.pix_key}
        error={formik.touched.pix_key && !!formik.errors.pix_key}
        helperText={formik.touched.pix_key && formik.errors.pix_key}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </>
  );
};

export default PixForm;
