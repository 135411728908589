import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Theme, useMediaQuery, GridSize } from '@material-ui/core';

import { useStyles } from './styles';
import Status from './Status';
import Chart from './Chart';
import Timeline from './Timeline';
import Contacts from './Contacts';
import BackLink from '../../components/BackLink';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import api from '../../services/api';
import { IndicationFullDTO, PaymentDTO } from '../../dtos/IndicationDTO';

interface HistoryLocationState {
  indicationId: number;
}

const initialState = {} as IndicationFullDTO;

const Indication: React.FC = () => {
  const [indication, setIndication] = useState(initialState);
  const [paymentData, setPaymentData] = useState<PaymentDTO[] | null>(null);
  const history = useHistory<HistoryLocationState>();
  const { indicationId } = history.location.state;
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();

  const getIndication = useCallback(async () => {
    try {
      const result = await api.get<IndicationFullDTO>(
        `/api/indication/${indicationId}`
      );
      setIndication(result.data);
    } catch (error) {
      showApiError(error, 'Erro obtendo os dados da indicação');
    }
  }, [indicationId, showApiError]);

  const getChartData = useCallback(() => {
    api
      .get<PaymentDTO[]>(
        `/api/payment-reports/indication-chart/${indicationId}`
      )
      .then((result) => {
        setPaymentData(result.data);
      })
      .catch((error) => {
        showApiError(error, 'Erro carregando os dados de pagamento');
      });
  }, [indicationId, showApiError]);

  useEffect(() => {
    startLoading();
    Promise.all([getIndication(), getChartData()]).finally(() => {
      endLoading();
    });
    getIndication();
    return () => setIndication(initialState);
  }, [endLoading, getChartData, getIndication, startLoading]);

  return (
    <>
      <div className={classes.backBtn}>
        <BackLink floating={false} color="dark" />
      </div>
      <Grid container spacing={matches ? 3 : 0}>
        <Grid item xs={12} lg={8}>
          {matches ? (
            <Chart indication={indication} paymentData={paymentData} />
          ) : (
            <Status indication={indication} />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {matches ? (
            <>
              <Status indication={indication} />
              <Timeline indication={indication} />
            </>
          ) : (
            <Chart indication={indication} paymentData={paymentData} />
          )}
        </Grid>
        {!matches && <Timeline indication={indication} />}
        {indication.contacts && (
          <Grid item xs={12} lg={(4 * indication.contacts.length) as GridSize}>
            <Contacts indication={indication} onUpdate={getIndication} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Indication;
