import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: 389,
    maxWidth: '95%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 1500,
    },
  },
  backBtn: {
    width: 389,
    maxWidth: '95%',
    textAlign: 'left',
    margin: 30,
  },
  container: {
    background: theme.palette.background.paper,
    width: 389,
    maxWidth: '95%',
    padding: 50,
    margin: '0 auto 15px',
    cursor: 'pointer',
  },
  avatarContainer: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarImgContainer: {
    position: 'relative',
    padding: 5,
  },
  avatarEditBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 4,
    color: '#fff',
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 5,
    transition: 'all 0.3s ease',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  name: {
    fontSize: '24px',
    fontWeight: 600,
    marginTop: 12,
    marginBottom: 30,
  },
  preview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .preview-icon': {
      fontSize: '20px',
      marginRight: 10,
      color: theme.palette.primary.main,
    },
    '& span': {
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
  },
  avatarEdition: {
    width: '100%',
    '& .title': {
      fontSize: '20px',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 35,
    },
    '& .img-preview': {
      height: 200,
      width: 200,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      overflow: 'hidden',
      position: 'relative',
    },
    '& .preview-text': {
      position: 'absolute',
      bottom: '10%',
      margin: '0 auto',
      padding: 8,
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.5)',
      borderRadius: 5,
    },
    '& .cropper-container': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 10,
      marginBottom: 20,
    },
  },
  closeBtn: {
    float: 'right',
    margin: 10,
    color: '#000',
  },
  formContainer: {
    background: theme.palette.background.paper,
    width: 389,
    maxWidth: '95%',
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
      width: 600,
    },
    padding: '35px 20px',
  },
  formTitle: {
    fontSize: '20px',
    fontWeight: 700,
    marginLeft: 40,
    marginBottom: 35,
  },
  field: {
    marginBottom: 10,
  },
  paymentSelect: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '18px 30px 35px',
  },
  modalContainer: {
    '& .MuiDialog-paper': {
      width: '95%',
      maxWidth: 400,
      padding: '35px 45px',
    },
    '& .text': {
      fontSize: '13px',
      textAlign: 'center',
      color: '#77838F',
    },
    '& .action-text': {
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 700,
      textAlign: 'center',
      marginTop: 15,
      marginBottom: 25,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: 8,
    },
    '& .resend-container': {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 35,
    },
    '& .resend-message': {
      fontSize: '10px',
      color: theme.palette.primary.main,
    },
    '& .resend-button': {
      marginLeft: 4,
      padding: 1,
      '& .MuiButton-label': {
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    '& .activation-timer': {
      fontSize: '8px',
      color: '#7E7E7E',
      marginLeft: 2,
    },
  },
}));
