import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 10,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 389,
    maxWidth: '95%',
    margin: '0 auto',
    padding: '20px 20px 20px 5px',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  menuButton: {
    width: 50,
    height: 50,
  },
  userSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& .logout-btn': {
      color: '#FFFFFF',
    },
  },
}));
