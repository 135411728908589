import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';

export interface ToastProps {
  message: string | string[];
  type: Color;
}

interface ToastContextData {
  addToast(toast: ToastProps): void;
  removeToast(): void;
}

const initialValue = {} as ToastProps;

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [currentToast, setCurrentToast] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (toasts.length) {
      setCurrentToast(toasts[0]);
      setIsOpen(true);

      setTimeout(() => {
        setIsOpen(false);
        setToasts((oldState) => oldState.slice(1));
      }, 4000);
    }
  }, [toasts]);

  const addToast = useCallback((data: ToastProps) => {
    setToasts((oldState) => {
      const newState: ToastProps[] = [];
      if (Array.isArray(data.message)) {
        data.message.forEach((item) =>
          newState.push({ message: item, type: data.type })
        );
      } else {
        newState.push(data);
      }
      return [...oldState, ...newState];
    });
  }, []);

  const removeToast = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpen}
      >
        <Alert severity={currentToast.type} elevation={6} variant="filled">
          {currentToast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextData => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
};

export { ToastProvider, useToast };
