import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    paddingTop: 90,
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: 30,
    },
    // [theme.breakpoints.only('md')]: {},
    [theme.breakpoints.up('md')]: {
      paddingLeft: 322,
      paddingRight: 30,
      paddingBottom: 100,
    },
  },
}));
