import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';
import { PartialFormProps } from './props';

const AccountOwnerForm: React.FC<PartialFormProps> = ({ formik }) => {
  const classes = useStyles();

  return (
    <>
      <Box textAlign="center">
        <FormControlLabel
          control={
            <Checkbox
              name="account_owner_is_user"
              checked={!formik.values.account_owner_is_user}
              color="primary"
              value={formik.values.account_owner_is_user}
              onChange={() =>
                formik.setFieldValue(
                  'account_owner_is_user',
                  !formik.values.account_owner_is_user
                )
              }
            />
          }
          label="Conta de terceiro?"
        />
      </Box>
      {!formik.values.account_owner_is_user && (
        <>
          <TextField
            id="account_owner_fisrtname"
            name="account_owner_fisrtname"
            label="Nome"
            className={classes.field}
            variant="outlined"
            fullWidth
            autoComplete="new-account_owner_fisrtname"
            value={formik.values.account_owner_fisrtname}
            error={
              formik.touched.account_owner_fisrtname &&
              !!formik.errors.account_owner_fisrtname
            }
            helperText={
              formik.touched.account_owner_fisrtname &&
              formik.errors.account_owner_fisrtname
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="account_owner_lastname"
            name="account_owner_lastname"
            label="Sobrenome"
            className={classes.field}
            variant="outlined"
            fullWidth
            autoComplete="new-account_owner_lastname"
            value={formik.values.account_owner_lastname}
            error={
              formik.touched.account_owner_lastname &&
              !!formik.errors.account_owner_lastname
            }
            helperText={
              formik.touched.account_owner_lastname &&
              formik.errors.account_owner_lastname
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="account_owner_document"
            name="account_owner_document"
            label="CPF"
            className={classes.field}
            variant="outlined"
            fullWidth
            autoComplete="new-account_owner_document"
            value={formik.values.account_owner_document}
            error={
              formik.touched.account_owner_document &&
              !!formik.errors.account_owner_document
            }
            helperText={
              formik.touched.account_owner_document &&
              formik.errors.account_owner_document
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="account_owner_phone"
            name="account_owner_phone"
            label="Telefone"
            className={classes.field}
            variant="outlined"
            fullWidth
            autoComplete="new-account_owner_phone"
            value={formik.values.account_owner_phone}
            error={
              formik.touched.account_owner_phone &&
              !!formik.errors.account_owner_phone
            }
            helperText={
              formik.touched.account_owner_phone &&
              formik.errors.account_owner_phone
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </>
      )}
    </>
  );
};

export default AccountOwnerForm;
