import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginRight: 200,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: 230,
    marginTop: 80,
    marginBottom: 30,
  },
  titleContainer: {
    width: '100%',
  },
  title: {
    fontSize: '29px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  division: {
    width: 90,
    height: 2,
    background: '#4870ff',
    marginTop: 7,
    marginBottom: 12,
  },
  text: {
    fontSize: '14px',
  },
});
