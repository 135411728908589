import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@material-ui/core';
import { FaRegTrashAlt, FaTimes } from 'react-icons/fa';

import { useStyles } from './styles';
import { ContactDTO } from '../../dtos/IndicationDTO';
import api from '../../services/api';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';

interface ContactEditProps {
  contact: ContactDTO;
  onUpdate: () => void;
}

const ContactDelete: React.FC<ContactEditProps> = ({ contact, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const classes = useStyles();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    startLoading();
    api
      .delete(`/api/indication-contact/${contact.id}`)
      .then(() => {
        handleClose();
        onUpdate();
        addToast({
          message: 'Contato eliminado com sucesso',
          type: 'success',
        });
      })
      .catch((error) => {
        showApiError(error, 'Erro eliminando o contato');
      })
      .finally(() => {
        endLoading();
      });
  }, [
    startLoading,
    contact.id,
    handleClose,
    onUpdate,
    addToast,
    showApiError,
    endLoading,
  ]);

  return (
    <>
      <IconButton title="Eliminar" onClick={handleOpen}>
        <FaRegTrashAlt size={18} />
      </IconButton>
      <Dialog open={isOpen}>
        <div>
          <IconButton onClick={handleClose} className={classes.closeBtn}>
            <FaTimes />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja eliminar esse contato?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box className={classes.btnContainer}>
            <Button color="secondary" variant="contained" onClick={handleClose}>
              Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Eliminar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContactDelete;
