import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

import { useStyles } from './styles';

interface LegalViewProps {
  open: boolean;
  onClose(): void;
}

const LegalView: React.FC<LegalViewProps> = ({ open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogContent className={classes.container}>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          className={classes.closeBtn}
        >
          Fechar
          <FaTimes />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LegalView;
