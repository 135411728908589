import React from 'react';
import { IMaskInput } from 'react-imask';

interface MaskProps {
  mask: string;
  startsWith?: string;
  lazy?: boolean;
  [key: string]: unknown;
}

interface MaskedInputProps {
  mask: string | MaskProps[];
}

interface InputProps {
  value: string;
}

const MaskedInput: React.FC<MaskedInputProps> = ({ mask, ...props }) => (
  <IMaskInput {...props} mask={mask} unmask />
);

export const CpfInput: React.FC<InputProps> = ({ ...props }) => (
  <MaskedInput mask="000.000.000-00" {...props} />
);

export const CnpjInput: React.FC<InputProps> = ({ ...props }) => (
  <MaskedInput mask="00.000.000/0000-00" {...props} />
);

export const PhoneInput: React.FC<InputProps> = ({ ...props }) => {
  const cellPhoneMask: MaskProps = { mask: '(00) 0000-00000' };
  // const fixedPhoneMask: MaskProps = { mask: '(00) 0000-0000' };
  return <MaskedInput mask={[cellPhoneMask]} {...props} />;
};
