import React, { createContext, useContext, useCallback, useState } from 'react';

import api from '../services/api';
import { IndicationStatusDTO } from '../dtos/IndicationDTO';

interface ContextData {
  statuses: IndicationStatusDTO[];
  getStatusList(): Promise<void>;
}

const IndicationStatusContext = createContext<ContextData>({} as ContextData);

const IndicationStatusProvider: React.FC = ({ children }) => {
  const [statuses, setStatuses] = useState<IndicationStatusDTO[]>([]);

  const getStatusList = useCallback(async () => {
    if (statuses.length === 0) {
      const result = await api.get<IndicationStatusDTO[]>(
        '/api/indication-status'
      );
      setStatuses(result.data);
    }
  }, [statuses]);

  return (
    <IndicationStatusContext.Provider
      value={{
        statuses,
        getStatusList,
      }}
    >
      {children}
    </IndicationStatusContext.Provider>
  );
};

const useIndicationStatus = (): ContextData => {
  const context = useContext(IndicationStatusContext);

  if (!context) {
    throw new Error(
      'useIndicationStatus must be used within a IndicationStatusProvider'
    );
  }

  return context;
};

export { IndicationStatusProvider, useIndicationStatus };
