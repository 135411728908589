import React from 'react';
import { Field } from 'formik';
import { Typography } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';

const Email: React.FC = () => {
  return (
    <>
      <Typography className="title">Qual o seu e-mail?</Typography>
      <div className="input">
        <Typography className="label">ex: pedro.**@gmail.com</Typography>
        <Field
          name="email"
          component={(props: TextFieldProps) => (
            <TextField
              {...props}
              inputProps={{
                ref: (element: HTMLInputElement) => {
                  if (element) element.focus();
                },
              }}
            />
          )}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default Email;
