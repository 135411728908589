import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .active': {
      display: 'block',
    },
    '& .hidden': {
      display: 'none',
    },
  },
  btnContainer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnNext: {
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    fontWeight: 700,
    padding: '12px 22px',
    border: `2px solid ${theme.palette.background.paper}`,
  },
  btnPrev: {
    background: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontWeight: 700,
    padding: '12px 22px',
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));
