export const pages = [
  'o_que_e',
  'como_fazer_parte',
  'formas_de_indicar',
  'criterios_qualificacao_indicado',
  'acompanhar_status_indicacao',
  'criterios_validacao_indicacao',
  'status_indicacao',
  'motivos_para_nao_avancar',
  'indicacao_repetida',
  'limite_indicacoes',
  'meus_ganhos',
  'resgate_ganhos',
  'criterios_validar_indicacao_lgpd',
  'recuperar_senha',
  'cancelar_cadastro',
] as const;

export type PageName = typeof pages[number];
