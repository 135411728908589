import React, { useCallback, useEffect } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, useField } from 'formik';

import { useLocalization } from '../../context/localization';

const Address: React.FC = () => {
  const [field] = useField('states');
  const { states, cities, getStates, getCities, resetCities } =
    useLocalization();

  useEffect(() => {
    if (states.length === 0) {
      getStates();
    }
  }, [states, getStates]);

  const handleSelectedState = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>) => {
      resetCities();
      const stateId = event.target.value;
      field.onChange(event);
      getCities(parseInt(stateId, 10));
    },
    [field, getCities, resetCities]
  );

  return (
    <>
      <Typography className="title">Aonde você mora atualmente?</Typography>
      <div className="input">
        <Typography className="label">Selecione seu estado.</Typography>
        <Field
          component={TextField}
          name="state"
          select
          fullWidth
          placeholder="Escreva sua resposta aqui..."
          onChange={handleSelectedState}
          SelectProps={{
            multiple: false,
          }}
        >
          {states.map((item) => (
            <MenuItem key={item.title} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Field>
      </div>
      <div className="input">
        <Typography className="label">Selecione sua cidade.</Typography>
        <Field
          component={TextField}
          name="city"
          select
          fullWidth
          placeholder="Escreva sua resposta aqui..."
          SelectProps={{
            multiple: false,
          }}
        >
          {cities.length === 0 ? (
            <MenuItem value="">Selecione um estado</MenuItem>
          ) : (
            cities.map((item) => (
              <MenuItem key={item.title} value={item.id}>
                {item.title}
              </MenuItem>
            ))
          )}
        </Field>
      </div>
    </>
  );
};

export default Address;
