import { createTheme } from '@material-ui/core/styles';
import '@fontsource/poppins';

import { palette } from './palette';

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: ['Poppins', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          margin: 0,
          padding: 0,
        },
        body: {
          margin: 0,
          padding: 0,
          height: '100vh',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 5,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        padding: '14px 45px',
      },
      label: {
        fontSize: '12px',
        fontWeight: 700,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 10,
      },
    },
  },
});
