/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { PageName } from './pagesList';
import { HelpFileProps } from './HelpView';

interface HelpCardProps {
  page: PageName;
}

const HelpCard: React.FC<HelpCardProps> = ({ page }) => {
  const [content, setContent] = useState<HelpFileProps | null>(null);
  const [icon, setIcon] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  useEffect(() => {
    const file = require(`./${page}.json`);
    setContent(file);

    return () => setContent(null);
  }, [page]);

  useEffect(() => {
    if (content?.icon) {
      const importIcon = async (): Promise<void> => {
        try {
          const result = await import(`../../assets/help/${content.icon}.svg`);
          setIcon(result.default);
        } catch (err) {
          console.error(err);
        }
      };
      importIcon();
    }
  }, [content]);

  const handleClick = useCallback(() => {
    history.push(`${pathname}/${page}`);
  }, [history, page, pathname]);

  return content ? (
    <Card className={classes.helpCard} onClick={handleClick}>
      <div className={classes.cardIcon}>
        {icon && <img src={icon} alt="" />}
      </div>
      <div className={classes.cardText}>
        <Typography component="div" className={classes.cardTitle}>
          {content.title}
        </Typography>
        <Typography className={classes.cardSubtitle}>
          {content.subtitle}
        </Typography>
      </div>
    </Card>
  ) : null;
};

export default HelpCard;
