import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 30,
    right: 30,
    color: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',

    '&:hover': {
      cursor: 'pointer',
    },

    '& .MuiTypography-root': {
      fontSize: '20px',
      fontWeight: 700,
      textTransform: 'none',
      marginRight: 10,
    },
  },
}));
