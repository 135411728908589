import React, { useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  TextField,
  Paper,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { useStyles } from './styles';
import SocialLogin from './SocialLogin';
import { cpfValidator, emailValidator } from '../../utils/validators';
import { useAuth } from '../../context/auth';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';

import { ReactComponent as Logo } from '../../assets/logo_v.svg';

interface LoginFormData {
  login: string;
  password: string;
}

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = useAuth();
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (values: LoginFormData) => {
      startLoading();
      try {
        await signIn(values);

        history.push('/');
        addToast({
          message: 'Login efetuado com sucesso',
          type: 'success',
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const message = error?.response?.data?.error || 'Erro fazendo login';
        showApiError(error, message);
        endLoading();
      }
    },
    [startLoading, signIn, history, addToast, showApiError, endLoading]
  );

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      login: Yup.string()
        .required('Documento obrigatório')
        .test(
          'EmailOrCpf',
          'Documento inválido',
          (value) => cpfValidator(value) || emailValidator(value)
        ),
      password: Yup.string()
        .required('Senha obrigatória')
        .min(8, 'Mínimo de 8 caracteres'),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleLogonClick = useCallback(() => {
    history.push({
      pathname: '/cadastro',
      search,
    });
  }, [history, search]);

  return (
    <div className={classes.root}>
      <Paper className={classes.container}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography className={classes.title}>
          Faça login na sua conta
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="login"
            name="login"
            label="E-mail / CPF"
            className={classes.field}
            variant="outlined"
            fullWidth
            error={formik.touched.login && !!formik.errors.login}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.login}
            helperText={(formik.touched.login && formik.errors.login) || null}
          />
          <TextField
            id="password"
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            className={classes.field}
            variant="outlined"
            fullWidth
            error={formik.touched.password && !!formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            helperText={
              (formik.touched.password && formik.errors.password) || null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={handleShowPassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            className={classes.loginButton}
          >
            Login
          </Button>
        </form>
        <Link to="/forgot_password" className={classes.forgotPassword}>
          Esqueceu a senha?
        </Link>
        <div className={classes.logonContainer}>
          <Typography component="span" className={classes.logonText}>
            Não tem uma conta?
          </Typography>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            className={classes.logonButton}
            onClick={handleLogonClick}
          >
            Cadastre-se
          </Button>
        </div>
        <SocialLogin />
      </Paper>
    </div>
  );
};

export default Login;
