import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CircularProgress, Button } from '@material-ui/core';

import { useStyles } from './styles';

type StepProps = Pick<
  FormikConfig<FormikValues>,
  'children' | 'validationSchema'
>;

export const FormStep: React.FC<StepProps> = ({ children }) => {
  return <>{children}</>;
};

export const FormStepper: React.FC<FormikConfig<FormikValues>> = ({
  children,
  onSubmit,
  ...props
}) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<StepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const classes = useStyles();

  const isLastStep = useCallback((): boolean => {
    return step === childrenArray.length - 1;
  }, [childrenArray, step]);

  const getButtonLabel = useCallback(
    (submitting: boolean): string => {
      if (submitting) return 'Enviando...';
      return isLastStep() ? 'Salvar' : 'Próximo';
    },
    [isLastStep]
  );

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await onSubmit(values, helpers);
        } else {
          setStep((s) => s + 1);
        }
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off" className={classes.root}>
          {currentChild}

          <div className={classes.btnContainer}>
            {step > 0 ? (
              <Button
                disabled={isSubmitting}
                variant="outlined"
                color="primary"
                className={classes.btnPrev}
                onClick={() => setStep((s) => s - 1)}
              >
                Voltar
              </Button>
            ) : null}
            <Button
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              variant="contained"
              type="submit"
              className={classes.btnNext}
            >
              {getButtonLabel(isSubmitting)}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
