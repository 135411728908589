/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataset } from 'chart.js';
import { FaCaretDown } from 'react-icons/fa';

import { useStyles } from './styles';
import { getCurrencyBr } from '../../utils/format';
import { WithdrawChartDTO } from '../../dtos/WithdrawDTO';

interface ChartProps {
  withdraws: WithdrawChartDTO[];
  months: number;
  onMonthsChange(month: number): void;
}

interface LegendProps {
  id: number;
  amount: number;
  title: string;
  color: string;
}

interface TotalsProps {
  available: number;
  paid: number;
  receivable: number;
}

const getDatasets = (
  data: WithdrawChartDTO[]
): {
  datasets: ChartDataset<'bar'>[];
  labels: string[];
  totals: TotalsProps;
} => {
  const datasets: ChartDataset<'bar'>[] = [];
  const labels: string[] = [];
  const totals: TotalsProps = { available: 0, paid: 0, receivable: 0 };

  if (data.length) {
    const availableDataset: ChartDataset<'bar'> = {
      data: [],
      type: 'bar',
      backgroundColor: '#0054F0',
      borderColor: 'transparent',
      label: 'Disponível',
    };
    const paidDataset: ChartDataset<'bar'> = {
      data: [],
      type: 'bar',
      backgroundColor: '#4DC765',
      borderColor: 'transparent',
      label: 'Solicitado',
    };
    const receivableDataset: ChartDataset<'bar'> = {
      data: [],
      type: 'bar',
      backgroundColor: '#FF274F',
      borderColor: 'transparent',
      label: 'A receber',
    };
    data.forEach((item) => {
      availableDataset.data.push(item.available);
      totals.available += item.available;
      paidDataset.data.push(item.paid);
      totals.paid += item.paid;
      receivableDataset.data.push(item.receivable);
      totals.receivable += item.receivable;
      const year = item.date.payment_liberate_date.split('-')[0];
      labels.push(`${item.date.abbreviate} / ${year.slice(-2)}`);
    });
    datasets.push(availableDataset, paidDataset, receivableDataset);
  }
  return {
    datasets,
    labels,
    totals,
  };
};

const ChartComponent: React.FC<ChartProps> = ({
  withdraws,
  months,
  onMonthsChange,
}) => {
  const [chartData, setChartData] = useState<ChartData<'bar'> | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [legends, setLegends] = useState<LegendProps[] | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const formattedData = getDatasets(withdraws);
    setChartData({
      datasets: formattedData.datasets,
      labels: formattedData.labels,
    });
    setLegends([
      {
        id: 1,
        amount: formattedData.totals.available,
        color: '#0054F0',
        title: 'Disponível',
      },
      {
        id: 2,
        amount: formattedData.totals.receivable,
        color: '#FF274F',
        title: 'A receber',
      },
      {
        id: 3,
        amount: formattedData.totals.paid,
        color: '#4DC765',
        title: 'Solicitado',
      },
    ]);
    return () => {
      setChartData(null);
      setLegends(null);
    };
  }, [withdraws]);

  const handleBtnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMonthsChange = useCallback(
    (newRange: number) => {
      if (newRange !== months) {
        onMonthsChange(newRange);
      }
      handleClose();
    },
    [months, handleClose, onMonthsChange]
  );

  return (
    <Paper className={classes.container}>
      <Typography component="div" className={classes.title}>
        Gráfico de ganhos
      </Typography>
      {chartData ? (
        <div className={classes.chartContainer}>
          <div
            className="chart-legend"
            style={
              legends && legends.length === 1
                ? { justifyContent: 'center' }
                : undefined
            }
          >
            {legends &&
              legends.map((legend) => (
                <div className="legend-item" key={legend.id}>
                  <div
                    className="label-circle"
                    style={{ backgroundColor: legend.color }}
                  />
                  <div>
                    <Typography component="div" className="label-title">
                      {legend.title}
                    </Typography>
                    <Typography component="div" className="label-amount">
                      {getCurrencyBr(legend.amount)}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <div className={classes.rangeBtnContainer}>
              <Button
                color="primary"
                className={classes.rangeBtn}
                onClick={handleBtnClick}
              >
                {months} {months === 1 ? 'mês' : 'meses'}
                <FaCaretDown />
              </Button>
              <Menu open={!!anchorEl} onClose={handleClose} anchorEl={anchorEl}>
                <MenuItem onClick={() => handleMonthsChange(3)}>
                  3 meses
                </MenuItem>
              </Menu>
            </div>
            <div className={classes.chartAreaContainer}>
              <Bar
                data={chartData}
                plugins={[
                  {
                    beforeRender: (chart: any) => {
                      const { max } = chart.scales.yAxes;
                      chart.config.data.datasets.forEach(
                        (item: ChartDataset) => {
                          Object.assign(item, { base: -max / 50 });
                        }
                      );
                    },
                  },
                ]}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: false,
                  },
                  datasets: { bar: { barThickness: 20 } },
                  elements: {
                    bar: { borderRadius: 6, borderWidth: 3 },
                  },
                  scales: {
                    yAxes: { grid: { display: true }, min: 0, offset: true },
                    xAxes: {
                      grouped: true,
                      grid: { display: false },
                      ticks: {
                        beginAtZero: true,
                        font: {
                          size: 14,
                          weight: 'bold',
                        },
                      },
                    },
                  },
                }}
              />
            </div>
            <Typography component="div" className={classes.textObservation}>
              <p>
                * Os valores indicados no gráfico acima se aplicam apenas ao
                nível Prata. Se você é nível Ouro ou Diamante será informado
                sobre seus ganhos por nossa equipe comercial.
              </p>
            </Typography>
          </div>
        </div>
      ) : null}
    </Paper>
  );
};

export default ChartComponent;
